<template>
	<div class="l-wrap">

		<header class="l-header">
			<p class="l-header__logo"><a href="./"><img src="img/lp/logo.png" alt="Digital Staging"></a></p>
			<div class="l-header__btn">
				<div class="digital-plan">
					<a href="https://digitalplan.digitalstaging.co.jp/" target="_blank" class="ov">
						<p class="txt01">3D間取り図から360°バーチャルツアーを手軽に簡単依頼！</p>
						<p class="txt02">
							<span class="logo"><img src="img/lp/logo_digital_plan.png" alt="Digital Plan"></span>
							<span class="txt">デジタルプランで注文</span>
						</p>
					</a>
				</div>
				<div class="entry">
					<a href="https://digitalstaging.co.jp/register" target="_blank" class="ov"><span>アカウント登録</span></a>
				</div>
				<div class="line">
					<a href="https://lin.ee/PPZulQF" class="ov"><span>LINEで相談・お問い合わせ</span></a>
				</div>
			</div>
		</header>
		
		<main class="l-main">
			<section class="c-hero">
				<div class="c-hero__inner define_inner">
					<p class="c-hero__inner-txt01">
						<span class="pcBlock">不動産事業者向け</span>
					</p>
					<h2 class="c-hero__inner-ttl">
						<span class="block">スマホで簡単！<br class="sp">不動産広告写真に<br class="sp">命を吹き込む！<br>ホームステージングを<br class="sp">CG加工で簡単注文！</span>
					</h2>
					<ul class="c-hero__inner-list">
						<li>
							<p class="num Oswald">1</p>
							<p class="txt01">写真の修正回数<br>
								<span class="marker">無制限</span></p>
						</li>
						<li>
							<p class="num Oswald">2</p>
							<p class="txt01">利用分のみの<br>
								<span class="marker">従量課金制</span></p>
						</li>
						<li>
							<p class="num Oswald">3</p>
							<p class="txt01">実際の<br class="sp">ホームステージング<br class="sp">ではなく<br class="pc"><span class="marker">家具を<br class="sp">入れる必要なし</span></p>
						</li>
						<li>
							<p class="num Oswald">4</p>
							<p class="txt01">プロの<br class="sp">カメラマンを<br>
								<span class="marker">呼ぶ必要なし</span></p>
						</li>
						<li>
							<p class="num Oswald">5</p>
							<p class="txt01">手軽に<span class="marker">スマホだけで<br>
								完結</span></p>
						</li>
					</ul>
					<div class="c-hero__inner-box-wrap">
						<section class="c-hero__inner-box">
							<h3 class="c-hero__inner-box-ttl">平均 <span class="Oswald">24</span> 時間／最大 <span class="Oswald">48</span> 時間納品</h3>
							<div class="c-hero__inner-box-list">
								<section class="c-hero__inner-box-list-sec">
									<h4 class="ttl"><span>デジタルステージング</span></h4>
									<p><span class="Oswald num">1</span>枚<span class="Oswald en">4,950</span>円<span class="tax">（税込）</span></p>
								</section>
								<section class="c-hero__inner-box-list-sec">
									<h4 class="ttl"><span>デジタルリノベーション</span></h4>
									<p><span class="Oswald num">1</span>枚<span class="Oswald en">11,000</span>円<span class="tax">（税込）</span></p>
								</section>
								<section class="c-hero__inner-box-list-sec">
									<h4 class="ttl"><span>家具消し</span></h4>
									<p><span class="Oswald num">1</span>枚<span class="Oswald en">3,850</span>円<span class="tax">（税込）</span></p>
								</section>
								<section class="c-hero__inner-box-list-sec">
									<h4 class="ttl"><span>商業用デジタルステージング</span></h4>
									<p><span class="Oswald num">1</span>枚<span class="Oswald en">8,800</span>円<span class="tax">（税込）</span></p>
								</section>
								<section class="c-hero__inner-box-list-sec">
									<h4 class="ttl"><span>360°デジタルステージング</span></h4>
									<p><span class="Oswald num">1</span>枚<span class="Oswald en">9,900</span>円<span class="tax">（税込）</span></p>
								</section>
							</div>
						</section>
					</div>
					<div class="c-hero__inner-btn">
						<a href="https://digitalstaging.co.jp/register" target="_blank" class="ov"><span>まずはアカウント登録してみる</span></a>
					</div>
				</div>
			</section>
			
			<div class="c-ai define_wrap">
				<div class="c-ai__inner define_inner">
					<div class="c-ai__inner-content">
						<div class="c-ai__inner-content-box">
							<div class="c-ai__inner-content-box-left">
								<h2 class="txt01">もっと早く！安く！を実現するために</h2>
								<p class="logo"><img src="/img/lp/ai_logo.png" alt=""></p>
							</div>
							<div class="c-ai__inner-content-box-right">
								<section class="sec">
									<p class="num">特<br>徴<br><span class="Oswald">1</span></p>
									<h3 class="ttl"><span class="marker-yellow">直感操作の<br>
										<span class="Oswald">10</span>秒生成</span></h3>
								</section>
								<section class="sec">
									<p class="num">特<br>徴<br><span class="Oswald">2</span></p>
									<h3 class="ttl"><span class="marker-yellow">床・壁・天井の<br>
										変更可能</span></h3>
								</section>
								<section class="sec">
									<p class="num">特<br>徴<br><span class="Oswald">3</span></p>
									<h3 class="ttl"><span class="marker-yellow">月々<span class="Oswald">5,500</span>円で<br>
										利用回数無制限</span></h3>
								</section>
							</div>
						</div>
						<div class="c-ai__inner-content-btn">
							<a href="https://ai.digitalstaging.co.jp/" target="_blank" class="ov"><span>デジタルステージングAIを<br class="sp">今すぐ試してしてみる</span></a>
						</div>
					</div>
				</div>
			</div>
			
			<section class="c-before-after">
				<div class="c-before-after__inner">
					<div class="c-before-after__inner-gallery"></div>
				</div>
			</section>
			
			<section class="c-user">
				<div class="c-user__head define_wrap">
					<div class="c-user__head-inner define_inner">
						<h2 class="c-title01">導入企業一覧</h2>
					</div>
				</div>
				<div class="c-user__logo">
					<div class="c-user__logo-gallery01"></div>
					<div class="c-user__logo-gallery02"></div>
				</div>
				<div class="c-user__info define_wrap">
					<div class="c-user__info-inner define_inner">
						<figure class="pic"><img src="/img/lp/user_pic01.png" alt="一般社団法人 日本ホームステージング協会"></figure>
						<p class="txt01">弊社は、日本ホームステージング協会が認定する<br class="sp">ホームステージャーの資格を<br class="pc">保有した<br class="sp">オペレーターにより運営されています。</p>
					</div>
				</div>
			</section>
			
			<div class="c-bgGray">
				<section class="c-about define_wrap">
					<div class="c-about__inner define_inner">
						<h2 class="c-title01 white">ホームステージングとは</h2>
						<div class="c-about__inner-content">
							<div class="content">
								<p>ホームステージングとは販売物件となる中古住宅や中古マンションの室内を家具や照明・小物などでモデルルームのように演出し、購入希望者に良い印象を与えて売却を促進するサービスです。</p>
								<p>モデルルームのように見栄えをよくし、リサーチを行った購入者層の嗜好に合致した室内を演出します。</p>
								<p>購入希望者にとっては家具などが配置されていることで購入後の生活をイメージしやすくなるため、空き家状態での内覧よりも物件の印象度が格段に良くなります。</p>
								<p>その結果、売主にとっては売却期間の短縮や高値での売却の可能性が高まるといった効果がございます。</p>
							</div>
							<figure class="pic"><img src="/img/lp/about_pic01.png" alt=""></figure>
						</div>
					</div>
				</section>
				
				<section class="c-reason define_wrap">
					<div class="c-reason__inner define_inner">
						<h2 class="c-title01">当社が選ばれる理由</h2>
						
						<div class="c-reason__inner-list">
							<section class="c-reason__inner-list-sec">
								<p class="num Oswald">1</p>
								<figure class="pic"><img src="/img/lp/reason_icon01.png" alt=""></figure>
								<h3 class="ttl" data-mh="ttl">ご依頼から編集のご確認まで<br class="sp"><span class="blue">手軽にスマホで完結</span></h3>
								<p class="txt01">当社独自のクラウドシステムにより、手軽にスマホからご依頼・ご発注ができ、編集のご確認までいただけるのでいつでもどこでもご利用可能です。</p>
							</section>
							<section class="c-reason__inner-list-sec">
								<p class="num Oswald">2</p>
								<figure class="pic"><img src="/img/lp/reason_icon02.png" alt=""></figure>
								<h3 class="ttl" data-mh="ttl">圧倒的に速い仕上がりと<br><span class="blue">リーズナブルな料金</span></h3>
								<p class="txt01">当社独自のシステム上でワンストップサービスを行なっているため、効率のよいサービスフローのスピード感と、圧倒的な安さでご満足いただけます。</p>
							</section>
							<section class="c-reason__inner-list-sec">
								<p class="num Oswald">3</p>
								<figure class="pic"><img src="/img/lp/reason_icon03.png" alt=""></figure>
								<h3 class="ttl" data-mh="ttl">ハイクオリティな画質と<br><span class="blue">編集技術</span></h3>
								<p class="txt01">プロの編集スタッフが常駐しており、ハイクオリティなその編集技術は他社の追随を許しておりません。是非お比べください。</p>
							</section>
						</div>
					</div>
				</section>
				
				<section class="c-case define_wrap">
					<div class="c-case__inner define_inner">
						<h2 class="c-title01">デジタルステージングの<br class="sp">利用事例</h2>
						
						<div class="c-case__inner-list">
							<section class="c-case__inner-list-sec">
								<h3 class="c-case__inner-list-sec-ttl">株式会社アミックス 野上 様</h3>
								<div class="c-case__inner-list-sec-result">
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list01_before01_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list01_after01_pc.png" alt=""></figure>
									</div>
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list01_before02_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list01_after02_pc.png" alt=""></figure>
									</div>
								</div>
								<section class="c-case__inner-list-sec-comment">
									<h4 class="ttl">ご担当者様からのコメント</h4>
									<div class="inner">
										<p>弊社では、２つの目的でデジタルステージングの活用をしています。<br>
											１つ目は、賃貸管理物件の入居者募集時です。入居希望者が部屋を選ぶときに、実際に住むイメージができて良いというお話をいただきます。何も置いてない部屋を見学するより有効かもしれません。<br>
											２つ目は、投資用不動産や実需不動産の販売時です。所有者の方に弊社ならデジタルステージングを活用して販売ができることをお伝えすると、依頼をしていただけることが増えました。また、販売用図面に掲載することで、購入希望者の方もイメージしていただきやすくなりました。<br>
											賃貸、売買ともに反響を多くいただけているので、今後も利用したいと思っています。</p>
									</div>
								</section>
							</section>
							<section class="c-case__inner-list-sec">
								<h3 class="c-case__inner-list-sec-ttl">シンクエージーアール株式会社<br class="sp">
									代表取締役 大野靖弘 様</h3>
								<div class="c-case__inner-list-sec-result">
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list02_before01_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list02_after01_pc.png" alt=""></figure>
									</div>
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list02_before02_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list02_after02_pc.png" alt=""></figure>
									</div>
								</div>
								<section class="c-case__inner-list-sec-comment">
									<h4 class="ttl">ご担当者様からのコメント</h4>
									<div class="inner">
										<p>賃貸用マンションの入居者様募集のポータルサイト掲載時に活用させて頂いています。今はお部屋探しの第一歩はインターネット検索が主流の時代です。まずは、そこで入居希望者様の目に留まる写真が求められます。その点、こちらのデジタルステージングのサービスでは、お客様が思わず目に留まる写真のインパクトがあります。さらに、入居後の生活もイメージして頂きやすくなり、お部屋を内見するキッカケになっていると思います。操作もスマホで簡単ですし、家具や小物のセンスも良く、リアルな質感で仕上がりにもとても満足しています。これからも活用させて頂ければと思います。</p>
									</div>
								</section>
							</section>
							<section class="c-case__inner-list-sec">
								<h3 class="c-case__inner-list-sec-ttl">家具消しの利用事例</h3>
								<div class="c-case__inner-list-sec-result">
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list04_before01_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list04_after01_pc.png" alt=""></figure>
									</div>
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list04_before02_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list04_after02_pc.png" alt=""></figure>
									</div>
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list04_before03_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list04_after03_pc.png" alt=""></figure>
									</div>
								</div>
							</section>
							<section class="c-case__inner-list-sec">
								<h3 class="c-case__inner-list-sec-ttl">360°デジタルステージングの利用事例</h3>
								<div class="c-case__inner-list-sec-result">
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list05_before01_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list05_after01_pc.png" alt=""></figure>
									</div>
								</div>
							</section>
							<section class="c-case__inner-list-sec">
								<h3 class="c-case__inner-list-sec-ttl">デジタルリノベーション事例</h3>
								<div class="c-case__inner-list-sec-result">
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list06_before01_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list06_after01_pc.png" alt=""></figure>
									</div>
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list06_before02_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list06_after02_pc.png" alt=""></figure>
									</div>
									<div class="c-case__inner-list-sec-result-box">
										<figure class="before"><img src="/img/lp/case_list06_before03_pc.png" alt=""></figure>
										<figure class="after"><img src="/img/lp/case_list06_after03_pc.png" alt=""></figure>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			
			<section class="c-effect define_wrap">
				<div class="c-effect__inner define_inner">
					<h2 class="c-title01 white">ホームステージング<br>
						実施後の効果</h2>
					<p class="c-effect__inner-txt01">実施後の効果は、日本ホームステージング協会のホームステージング 白書2020のデータです。</p>
					<div class="c-effect__inner-content">
						<div class="c-effect__inner-content-sec">
							<figure class="pic"><img src="/img/lp/effect_pic01.png" alt="PV数"></figure>
							<table>
								<tr>
									<td><span class="color color1"></span>大幅に増えた</td>
									<td><span class="color color2"></span>わずかに増えた</td>
								</tr>
								<tr>
									<td><span class="color color3"></span>変わらない</td>
									<td><span class="color color4"></span>不明</td>
								</tr>
							</table>
						</div>
						<div class="c-effect__inner-content-sec">
							<figure class="pic"><img src="/img/lp/effect_pic02.png" alt="問合せ数"></figure>
							<table>
								<tr>
									<td><span class="color color1"></span>大幅に増えた</td>
									<td><span class="color color2"></span>わずかに増えた</td>
								</tr>
								<tr>
									<td><span class="color color3"></span>変わらない</td>
									<td><span class="color color4"></span>不明</td>
								</tr>
							</table>
						</div>
						<div class="c-effect__inner-content-sec">
							<figure class="pic"><img src="/img/lp/effect_pic03.png" alt="内覧者数"></figure>
							<table>
								<tr>
									<td><span class="color color1"></span>大幅に増えた</td>
									<td><span class="color color2"></span>わずかに増えた</td>
								</tr>
								<tr>
									<td><span class="color color3"></span>変わらない</td>
									<td><span class="color color4"></span>不明</td>
								</tr>
							</table>
						</div>
						<div class="c-effect__inner-content-sec">
							<figure class="pic"><img src="/img/lp/effect_pic04.png" alt="内覧時間"></figure>
							<table>
								<tr>
									<td><span class="color color1"></span>大幅に長くなった</td>
									<td><span class="color color2"></span>わずかに長くなった</td>
								</tr>
								<tr>
									<td><span class="color color3"></span>変わらない</td>
									<td><span class="color color4"></span>不明</td>
								</tr>
							</table>
						</div>
						<div class="c-effect__inner-content-sec">
							<figure class="pic"><img src="/img/lp/effect_pic05.png" alt="成約までの期間"></figure>
							<table>
								<tr>
									<td><span class="color color1"></span>大幅に短くなった</td>
									<td><span class="color color2"></span>わずかに短くなった</td>
								</tr>
								<tr>
									<td><span class="color color3"></span>変わらない</td>
									<td><span class="color color4"></span>不明</td>
								</tr>
							</table>
						</div>
						<section class="c-effect__inner-content-sec">
							<h3 class="ttl">不動産仲介業</h3>
							<p class="txt01">ホームステージングは<br>効果があると思うか</p>
							<figure class="pic"><img src="/img/lp/effect_pic06.png" alt=""></figure>
							<table>
								<tr>
									<td><span class="color color1"></span>非常に効果があった</td>
								</tr>
								<tr>
									<td><span class="color color2"></span>多少は効果があった</td>
								</tr>
							</table>
							<p class="txt02">効果があったと<br class="sp">前向きな回答を<br>している方の割合</p>
							<p class="num Oswald"><span class="large">95</span>.9%</p>
						</section>
						<section class="c-effect__inner-content-sec">
							<h3 class="ttl">不動産賃貸業</h3>
							<p class="txt01">ホームステージングは<br>効果があると思うか</p>
							<figure class="pic"><img src="/img/lp/effect_pic07.png" alt=""></figure>
							<table>
								<tr>
									<td><span class="color color1"></span>非常に効果があった</td>
								</tr>
								<tr>
									<td><span class="color color2"></span>多少は効果があった</td>
								</tr>
							</table>
							<p class="txt02">効果があったと<br class="sp">前向きな回答を<br>している方の割合</p>
							<p class="num Oswald"><span class="large">95</span>.4%</p>
						</section>
					</div>
				</div>
			</section>
			
			<section class="c-price define_wrap">
				<div class="c-price__inner define_inner">
					<h2 class="c-title01">ご利用料金</h2>
					
					<section class="c-price__inner-table">
						<h3 class="c-title02">デジタルステージング</h3>
						<table>
							<thead>
								<tr>
									<th></th>
									<th class="our"><p class="txt01"><span><img src="/img/lp/price_logo_pc.png" alt="Digital Staging"></span></p></th>
									<th>A社</th>
									<th>B社</th>
									<th>C社</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>料金</th>
									<td class="our large">1枚<br class="sp"><span class="num Oswald">4,950</span>円</td>
									<td>１枚8,000円<br>
										<span class="small">（都度⾒積もり）</span></td>
									<td>１枚10,780円<br>
										<span class="small">（内容により<br class="sp">追加料金有り）</span></td>
									<td>１枚8,800円</td>
								</tr>
								<tr>
									<th>納期</th>
									<td class="our">48時間以内納品</td>
									<td>最短3営業日</td>
									<td>最短5営業日</td>
									<td>最短3営業日</td>
								</tr>
								<tr>
									<th>その他</th>
									<td class="our"><p class="txt01"><span>発注から<br class="sp">納品まで<br>スマホ上で完結</span></p></td>
									<td>担当者とメールで<br>詳細連絡必要</td>
									<td>オプションで<br>最大10営業日</td>
									<td>担当者とメールで<br>要連絡</td>
								</tr>
							</tbody>
						</table>
					</section>
					<section class="c-price__inner-table">
						<h3 class="c-title02">360°デジタルステージング</h3>
						<table>
							<thead>
								<tr>
									<th></th>
									<th class="our"><p class="txt01"><span><img src="/img/lp/price_logo_pc.png" alt="Digital Staging"></span></p></th>
									<th>A社</th>
									<th>B社</th>
									<th>C社</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>料金</th>
									<td class="our large">1枚<br class="sp"><span class="num Oswald">9,900</span>円</td>
									<td>１枚11,000円<br>
										<span class="small">（都度⾒積もり）</span></td>
									<td>１枚19,580円<br>
										<span class="small">（内容により<br class="sp">追加料金有り）</span></td>
									<td>１枚15,400円</td>
								</tr>
								<tr>
									<th>納期</th>
									<td class="our">48時間以内納品</td>
									<td>最短5営業日</td>
									<td>最短5営業日</td>
									<td>最短3営業日</td>
								</tr>
								<tr>
									<th>その他</th>
									<td class="our"><p class="txt01"><span>発注から<br class="sp">納品まで<br>スマホ上で完結</span></p></td>
									<td>担当者とメールで<br>詳細連絡必要</td>
									<td>オプションで<br>最大10営業日</td>
									<td>担当者とメールで<br>要連絡</td>
								</tr>
							</tbody>
						</table>
					</section>
					<section class="c-price__inner-table">
						<h3 class="c-title02">家具消し</h3>
						<table>
							<thead>
								<tr>
									<th></th>
									<th class="our"><p class="txt01"><span><img src="/img/lp/price_logo_pc.png" alt="Digital Staging"></span></p></th>
									<th>A社</th>
									<th>B社</th>
									<th>C社</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>料金</th>
									<td class="our large">1枚<br class="sp"><span class="num Oswald">3,850</span>円</td>
									<td>１枚8,000円<br>
										<span class="small">（都度⾒積もり）</span></td>
									<td>１枚6,028円<br>
										<span class="small">（内容により<br class="sp">追加料金有り）</span></td>
									<td>１枚8,800円</td>
								</tr>
								<tr>
									<th>納期</th>
									<td class="our">48時間以内納品</td>
									<td>最短5営業日</td>
									<td>最短5営業日</td>
									<td>最短3営業日</td>
								</tr>
								<tr>
									<th>その他</th>
									<td class="our"><p class="txt01"><span>発注から<br class="sp">納品まで<br>スマホ上で完結</span></p></td>
									<td>担当者とメールで<br>詳細連絡必要</td>
									<td>-</td>
									<td>担当者とメールで<br>要連絡</td>
								</tr>
							</tbody>
						</table>
					</section>
				</div>
			</section>
			
			<section class="c-cv define_wrap">
				<div class="c-cv__inner define_inner">
					<h2 class="c-cv__inner-ttl">不動産オーナー向け<br class="sp">ホームステージングご検討なら<br>
						無料相談できる当社へ<br class="sp">お気軽にご相談ください！</h2>
					<div class="c-cv__inner-btn">
						<div class="entry">
							<a href="https://digitalstaging.co.jp/register" target="_blank" class="ov"><span>アカウント登録</span></a>
						</div>
						<div class="line">
							<a href="https://lin.ee/PPZulQF" class="ov"><span>LINEで相談・お問い合わせ</span></a>
						</div>
					</div>
				</div>
			</section>
			
			<section class="c-flow define_wrap">
				<div class="c-flow__inner define_inner">
					<h2 class="c-title01">ご登録から納品までの流れ</h2>
				
					<div class="c-flow__inner-list">
						<section class="c-flow__inner-list-sec">
							<p class="num Oswald">1</p>
							<figure class="pic"><img src="/img/lp/flow_icon00.png" alt=""></figure>
							<h3	class="ttl" data-mh="ttl">サービスの<br>選択</h3>
						</section>
						<section class="c-flow__inner-list-sec">
							<p class="num Oswald">2</p>
							<figure class="pic"><img src="/img/lp/flow_icon01.png" alt=""></figure>
							<h3	class="ttl" data-mh="ttl">デザイン<br>スタイルの選択</h3>
						</section>
						<section class="c-flow__inner-list-sec">
							<p class="num Oswald">3</p>
							<figure class="pic"><img src="/img/lp/flow_icon02.png" alt=""></figure>
							<h3	class="ttl" data-mh="ttl">画像の<br>アップロード</h3>
						</section>
						<section class="c-flow__inner-list-sec">
							<p class="num Oswald">4</p>
							<figure class="pic"><img src="/img/lp/flow_icon03.png" alt=""></figure>
							<h3	class="ttl" data-mh="ttl">アップロードした<br>部屋の選択</h3>
						</section>
						<section class="c-flow__inner-list-sec">
							<p class="num Oswald">5</p>
							<figure class="pic"><img src="/img/lp/flow_icon04.png" alt=""></figure>
							<h3	class="ttl" data-mh="ttl">設置する<br>家具の選択</h3>
						</section>
						<section class="c-flow__inner-list-sec">
							<p class="num Oswald">6</p>
							<figure class="pic"><img src="/img/lp/flow_icon05.png" alt=""></figure>
							<h3	class="ttl" data-mh="ttl">追加オプションの選択</h3>
						</section>
						<section class="c-flow__inner-list-sec">
							<p class="num Oswald">7</p>
							<figure class="pic"><img src="/img/lp/flow_icon06.png" alt=""></figure>
							<h3	class="ttl" data-mh="ttl">注文内容の<br>確認と決済</h3>
						</section>
						<section class="c-flow__inner-list-sec">
							<p class="num Oswald">8</p>
							<figure class="pic"><img src="/img/lp/flow_icon07.png" alt=""></figure>
							<h3	class="ttl" data-mh="ttl">当社にて<br>編集</h3>
						</section>
						<section class="c-flow__inner-list-sec">
							<p class="num Oswald">9</p>
							<figure class="pic"><img src="/img/lp/flow_icon08.png" alt=""></figure>
							<h3	class="ttl" data-mh="ttl">依頼者様による<br>編集写真の確認</h3>
						</section>
						<section class="c-flow__inner-list-sec">
							<p class="num Oswald">10</p>
							<figure class="pic"><img src="/img/lp/flow_icon09.png" alt=""></figure>
							<h3	class="ttl" data-mh="ttl">納品</h3>
						</section>
					</div>
				</div>
			</section>
			
			<section class="c-faq define_wrap c-bgDot01">
				<div class="c-faq__inner define_inner">
					<h2 class="c-title01">よくあるご質問</h2>
					
					<div class="c-faq__inner-list">
						<dl>
							<dt>
								<p class="icon Oswald">Q</p>
								<h2 class="ttl">写真は同じ部屋でも1枚毎に4,950円でしょうか？</h2>
							</dt>
							<dd>
								<p class="icon Oswald">A</p>
								<div class="cont">
									<p>はい。同じ部屋でも1枚毎に4,950円です。</p>
								</div>
							</dd>
						</dl>
						<dl>
							<dt>
								<p class="icon Oswald">Q</p>
								<h2 class="ttl">何枚掲載するとポータルサイト上で効果がありますか？</h2>
							</dt>
							<dd>
								<p class="icon Oswald">A</p>
								<div class="cont">
									<p>複数枚掲載することをお勧めしています。リビングで2枚、ベットルームで1枚、合計3枚を掲載すると非常に見栄えがします。</p>
								</div>
							</dd>
						</dl>
						<dl>
							<dt>
								<p class="icon Oswald">Q</p>
								<h2 class="ttl">オフィスのステージングは出来ますか？</h2>
							</dt>
							<dd>
								<p class="icon Oswald">A</p>
								<div class="cont">
									<p>現在は住宅用のみのステージングですが、今後オフィスのステージングサービスも展開する予定です。</p>
								</div>
							</dd>
						</dl>
						<dl>
							<dt>
								<p class="icon Oswald">Q</p>
								<h2 class="ttl">クレジットカード払い以外に支払いは可能ですか？</h2>
							</dt>
							<dd>
								<p class="icon Oswald">A</p>
								<div class="cont">
									<p>法人の方のみ請求書払い（振込）による対応が可能です。末締めの翌月末払いとなります。</p>
								</div>
							</dd>
						</dl>
						<dl>
							<dt>
								<p class="icon Oswald">Q</p>
								<h2 class="ttl">オススメの写真撮影方法はありますか？</h2>
							</dt>
							<dd>
								<p class="icon Oswald">A</p>
								<div class="cont">
									<p>スマホの広角機能での撮影で十分クオリティの高い写真が撮れます。<br>
										詳しくは下記リンクよりご確認ください。</p>
									<div class="btn">
										<a href="pdf/photography-method.pdf" target="_blank" class="c-btn01 ov"><span>サービス利用時の<br class="sp">おすすめ写真撮影手法をみる</span></a>
									</div>
								</div>
							</dd>
						</dl>
						<dl>
							<dt>
								<p class="icon Oswald">Q</p>
								<h2 class="ttl">販促用のチラシはありますか？</h2>
							</dt>
							<dd>
								<p class="icon Oswald">A</p>
								<div class="cont">
									<p>管理獲得用、売買媒介獲得用の編集可能なチラシがあります。ご活用ください。</p>
									<div class="btn">
										<a href="pdf/leaflets.pdf" target="_blank" class="c-btn01 ov"><span>販促用チラシをみる</span></a>
									</div>
								</div>
							</dd>
						</dl>
					</div>
				</div>
			</section>
			
			<section class="c-company define_wrap">
				<div class="c-company__inner define_inner">
					<h2 class="c-title01">会社概要</h2>
				
					<div class="c-company__inner-list">
						<dl>
							<dt>会社名</dt>
							<dd>株式会社RealtyBank</dd>
						</dl>
						<dl>
							<dt>会社所在地</dt>
							<dd> 〒060-0041 北海道札幌市中央区大通東3丁目1-12 <br>クリーンリバー大通702号室</dd>
						</dl>
						<dl>
							<dt>代表取締役</dt>
							<dd>川上 将司</dd>
						</dl>
						<dl>
							<dt>事業内容</dt>
							<dd>不動産広告マーティング事業</dd>
						</dl>
					</div>
				</div>
			</section>
		</main>

		<div class="l-pageTop js-pageTop">
			<a href="#" class="ov"><img src="img/lp/pagetop.png" alt="page top"></a>
		</div>
		
		<div class="l-fixed-contents">
			<div class="entry">
				<a href="https://digitalstaging.co.jp/register" target="_blank"><span>アカウント登録</span></a>
			</div>
			<div class="line">
				<a href="https://lin.ee/PPZulQF" class="ov"><span>LINEで相談・お問い合わせ</span></a>
			</div>
		</div>
		
		<footer class="l-footer define_wrap">
			<div class="l-footer__inner define_inner">
				<p class="l-footer__inner-logo"><a href="./"><img src="img/lp/logo.png" alt="Digital Staging"></a></p>
				<p class="l-footer__inner-copy">© 2022 Digital Staging. All rights reserved.</p>
			</div>
		</footer>
	</div>
</template>
<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';
import $ from 'jquery' // ← 読み込む
export default {
	mounted(){
		const advertisement = this.$route.query.advertisement;
		const path = this.$route.fullPath;
		if (advertisement) {
			localStorage.setItem('_advertisement', advertisement);
		}
		localStorage.setItem('_path', path);
		//マウスオーバーで画像切り替え
		//start
		$(function(){
			$('a img').hover(function(){
				$(this).attr('src', $(this).attr('src').replace('_off', '_on'));
			}, function(){
				if (!$(this).hasClass('currentPage')) {
					$(this).attr('src', $(this).attr('src').replace('_on', '_off'));
				}
			});
		});
		//end

		$(function() {
			// 置換の対象とするclass属性。
			var $elem = $('img');
			// 置換の対象とするsrc属性の末尾の文字列。
			var sp = '_sp.';
			var pc = '_pc.';
			// 画像を切り替えるウィンドウサイズ。
			var replaceWidth = 768;

			function imageSwitch() {
				// ウィンドウサイズを取得する。
				var windowWidth = parseInt($(window).width());

				// ページ内にあるすべての`.js-image-switch`に適応される。
				$elem.each(function() {
					var $this = $(this);
					// ウィンドウサイズが768px以上であれば_spを_pcに置換する。
					if(windowWidth >= replaceWidth) {
						$this.attr('src', $this.attr('src').replace(sp, pc));

						// ウィンドウサイズが768px未満であれば_pcを_spに置換する。
					} else {
						$this.attr('src', $this.attr('src').replace(pc, sp));
					}
				});
			}
			imageSwitch();

			// 動的なリサイズは操作後0.2秒経ってから処理を実行する。
			var resizeTimer;
			$(window).on('resize', function() {
				clearTimeout(resizeTimer);
				resizeTimer = setTimeout(function() {
					imageSwitch();
				}, 200);
			});
		});

		//スムーズスクロール
		$(function(){
			$("a.js-scroll").click(function(){
				$('html,body').animate({ scrollTop: $($(this).attr("href")).offset().top }, 'slow','swing');
				return false;
			})
		});

		//ページトップ
		$(function() {
			var topBtn = $('.js-pageTop');
			topBtn.hide();
			//スクロールが100に達したらボタン表示
			$(window).scroll(function () {
				if ($(this).scrollTop() > 100) {
					topBtn.fadeIn();
				} else {
					topBtn.fadeOut();
				}
			});
			//スクロールしてトップ
			topBtn.click(function () {
				$('body,html').animate({
					scrollTop: 0
				}, 'slow','swing');
				return false;
			});
		});

		//アコーディオン
		$(function(){
			$(document).on("click", ".js-acc", function(){
				$(this).prev().stop(false,true).slideToggle();
				$(this).toggleClass("is-active");
				return false;
			});
		});

		//タブ
		$(function(){
			$(".js-tabHead li a").on("click", function() {
				var tabArea = $(this).parents('.js-tabArea');
				tabArea.find(".js-tabBox").hide();
				$($(this).attr("href")).fadeToggle();
				tabArea.find(".js-tabHead").find('a').removeClass("is-active");//追加部分
				$(this).toggleClass("is-active");//追加部分
				return false;
			});
		});

		//バリデーション
		// $(function(){
		//     $('.js-validation').validationEngine('attach', {
		//         promptPosition:"bottomLeft",
		//         scroll: true
		//     });
		// });
	}
}
</script>

<style scoped>
body {
	margin: 0px;
	padding: 0px;
	color: #000000;
	font-size: 16px;
	line-height: 1.8;
	letter-spacing: .07em;
	font-feature-settings : "palt";
	text-align: left;
	background-color: #fff;
	font-family: "YakuHanJP", 'Noto Sans JP', 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	/*font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, "メイリオ", "Meiryo", sans-serif;*/
	-webkit-text-size-adjust: none;
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden;
}
@media screen\0 {
	body {
		letter-spacing: normal;
		font-family: "YakuHanJP", 'Noto Sans JP', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	}
}
@media print {
	body {
		zoom: 0.68;
		-webkit-print-color-adjust: exact;
	}
}
/* IE10以上 */
@media print and (-ms-high-contrast: none) {
	/* @pageの指定いらないかも */
	@page {
		size: A4;
		margin: 12.7mm 9.7mm;
	}

	body {
		zoom: 1.8;
		width: 1200px;
		transform: scale(0.5);
		transform-origin: 0 0;
	}
}
div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, button, textarea, select, p, blockquote, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
	margin: 0px;
	padding: 0px;
	text-align: left;
}
main {
	display: block;
}
table {
	font-size: inherit; /* モダンブラウザ向け */
	font: 100%; /* Win IE 5-5.5､6(後方互換モード)向け */
}
fieldset, img {
	border:0;
}
address, caption, cite, code, dfn, em, strong, th, var {
	font-style:normal;
	font-weight:normal;
}
ol, ul {
	list-style:none;
}
caption, th {
	text-align:left;
}
h1, h2, h3, h4, h5, h6 {
	font-size:100%;
	font-weight:normal;
	letter-spacing: .07em;
}
p {
	letter-spacing: .07em;
}
q:before, q:after {
	content:'';
}
abbr, acronym {
	border:0;
}
input[type="radio"],input[type="checkbox"],label,button,input[type="submit"] {
	cursor: pointer;
}
input[type="submit"] {
	cursor: pointer;
}
input[type="text"], input[type="search"], input[type="tel"], input[type="url"], input[type="email"], input[type="password"], input[type="date"], input[type="number"] {
	height: 50px;
	padding: 5px 20px;
	font-family: "YakuHanJP", 'Noto Sans JP', 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	font-size: 16px;
	font-weight: bold;
	border: 3px solid #dddddd;
	box-sizing: border-box;
	border-radius: 0;
	-webkit-text-size-adjust: none;
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden;
}
select {
	height: 50px;
	padding: 5px 10px;
	font-family: "YakuHanJP", 'Noto Sans JP', 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	font-size: 16px;
	font-weight: bold;
	border: 3px solid #dddddd;
	box-sizing: border-box;
	border-radius: 0;
}
textarea {
	height: 150px;
	padding: 15px 20px;
	font-family: "YakuHanJP", 'Noto Sans JP', 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	font-size: 16px;
	font-weight: bold;
	border: 3px solid #dddddd;
	box-sizing: border-box;
	border-radius: 0;
	vertical-align: top;
}
/* Webkit */
::-webkit-input-placeholder {
	color: #b8b8b8;
	opacity: 1;
}
/* Firefox 18 以前 */
:-moz-placeholder {
	color: #b8b8b8;
}
/* Firefox 19 以降 */
::-moz-placeholder {
	color: #b8b8b8;

	/* Firefox 19 以降のデフォルトでは */
	/* color ではなく opacity で色合いを調整しているため */
	/* 文字色を指定する場合、opacity を 1 にする必要がある */
	opacity: 1;
}
/* IE 10 以降 */
:-ms-input-placeholder {
	color: #b8b8b8 !important;
}
/* CSS4では以下のような名前の擬似クラスになるらしい */
/* おそらく今のところ対応ブラウザはない */
:placeholder-shown {
	color: #b8b8b8;
}
/* Android chrome対策 */
div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, button, textarea, select, p, blockquote, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
	max-height:999999px;
}

.clear {
	clear: both;
}
.clearfix {
	zoom: 100%;
}
.clearfix:after {
	content: "";
	clear: both;
	height: 0;
	display: block;
	visibility: hidden;
}
a {
	text-decoration: none;
}
a:link {
	color: #000000;
}
a:visited {
	color: #000000;
}
a.normal:link {
	color: #000000;
}
a.normal:visited {
	color: #000000;
}
a:hover {
	text-decoration: none;
}
a:active {
}
a.noline {
	text-decoration: none;
}
a.reverse {
	text-decoration: none;
}
a.reverse:hover {
	text-decoration: underline;
}
.linkBox {
	cursor: pointer;
}
body {
	text-align: center;
}
img {
	max-width: 100%;
	width: auto;
	height: auto;
	vertical-align: middle;
	-webkit-backface-visibility: hidden;
}
a { /* FireFox リンク選択時の点線を消す*/
    overflow: hidden;
    outline: none;
}
.ov {
	transition: all 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.ov:hover {
	opacity:0.7;
	filter: alpha(opacity=70);        /* ie lt 8 */
	-ms-filter: "alpha(opacity=70)";  /* ie 8 */
	-moz-opacity:0.7;                 /* FF lt 1.5, Netscape */
	-khtml-opacity: 0.7;              /* Safari 1.x */
}
.ovImg:hover img {
	opacity:0.7;
	filter: alpha(opacity=70);        /* ie lt 8 */
	-ms-filter: "alpha(opacity=70)";  /* ie 8 */
	-moz-opacity:0.7;                 /* FF lt 1.5, Netscape */
	-khtml-opacity: 0.7;              /* Safari 1.x */
}
.nowrap {
	white-space: nowrap;
}
.mincho {
	font-family: "YakuHanMP", YuMincho, '游明朝', 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho ProN','メイリオ',"HGS明朝E", 'MS P 明朝', 'MS PMincho', serif;
}
@media screen\0 {
	.mincho {
		font-family: "YakuHanMP", 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho ProN','メイリオ',"HGS明朝E", 'MS P 明朝', 'MS PMincho', serif;
	}
}
.gothic {
	font-family: "YakuHanJP", 'Noto Sans JP', 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}
@media screen\0 {
	.gothic {
		font-family: "YakuHanJP", 'Noto Sans JP', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	}
}
.Lato {
	font-family: "YakuHanJP", 'Lato', sans-serif;
}
.Oswald {
	font-family: "YakuHanJP", 'Oswald', sans-serif;
}
.Roboto {
	font-family: "YakuHanJP", 'Roboto', sans-serif;
}
* {
	box-sizing: border-box;
}


@media screen and (max-width:767px){
	body {
		font-size: 14px;
	}
	input[type="text"], input[type="search"], input[type="tel"], input[type="url"], input[type="email"], input[type="password"], input[type="date"], input[type="number"] {
		height: 44px;
		padding: 5px 10px;
		font-size: 16px;
		border: 2px solid #dddddd;
	}
	select {
		height: 44px;
		padding: 5px 10px;
		font-size: 16px;
		border: 2px solid #dddddd;
	}
	textarea {
		height: 34.6666666vw;
		padding: 5px 10px;
		font-size: 16px;
		border: 2px solid #dddddd;
	}
}

/**********************************

 general

***********************************/
.imgMax {
	max-width: none;
	width: 100%;
}
.floatL {
	float: left;
}
.floatR {
	float: right;
}
.alignC {
	text-align: center;
}
.alignR {
	text-align: right;
}
.alignL {
	text-align: left;
}
.veralignT {
	vertical-align: top;
}
.veralignM {
	vertical-align: middle;
}
.veralignB {
	vertical-align: bottom;
}
.indent {
	text-indent: -1em;
	padding-left: 1em;
}
.bold {
	font-weight: bold;
}
.red {
	color: #ff3f00;
}
.blue {
	color: #0c509f;
	background: none;
}
.yellow {
	color: #ffff00;
}
.bgYellow {
	background-color: #fff100;
}
.marker {
	background: linear-gradient(transparent 70%, #ff9300 70%);
}
@media screen\0 {
	.marker {
		font-family: "YakuHanJP", Lato, メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	}
}
.marker-yellow {
	background: linear-gradient(transparent 70%, #ffff00 70%);
}
@media screen\0 {
	.marker-yellow {
		font-family: "YakuHanJP", Lato, メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	}
}
.underline {
	text-decoration: underline;
}

@media screen and (min-width:768px){
	.sp {
		display: none !important;
	}
}
@media screen and (max-width:767px){
	.pc {
		display: none !important;
	}
}

/**********************************

 parts

***********************************/
.define_wrap {
	padding: 0 50px;
}

.define_inner {
	max-width: 100%;
	width: 1000px;
	margin: 0 auto;
}


@media screen and (max-width:1099px){

	.define_wrap {
		padding: 0 4.545454545454545%;
	}
}


@media screen and (max-width:767px){

	.define_wrap {
		padding: 0 4.66666667%;
	}
}

/**********************************

 layout

***********************************/
.l-wrap {
	overflow: hidden;
}

.l-main {
}

.l-pageTop {
	display: none;
	position: fixed;
	bottom: 40px;
	right: 40px;
	z-index: 5;
}

@media screen and (max-width:1023px){

	.l-pageTop {
		width: 60px;
		bottom: 24px;
		right: 24px;
	}
}

@media screen and (max-width:767px){
	.l-wrap {
		padding-bottom: 50px;
	}

	.l-pageTop {
		width: 32px;
		bottom: 65px;
		right: 4%;
	}
}

/**********************************

 l-header

***********************************/
.l-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100px;
	background: #fff;
}

.l-header__logo {
	margin-left: 60px;
}

.l-header__btn {
	display: flex;
	align-items: center;
	margin-right: 60px;
}

.l-header__btn .digital-plan {
	margin-right: 20px;
}

.l-header__btn .digital-plan a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 54px;
	padding: 0 24px;
	border: 3px solid #0c509f;
	border-radius: 100px;
	position: relative;
	z-index: 1;
	overflow: visible;
}

.l-header__btn .digital-plan a .txt01 {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	padding: 0 12px;
	margin-top: -26px;
	font-size: 12px;
	font-weight: bold;
	white-space: nowrap;
	line-height: 1.5;
	background: #ffff00;
	border-radius: 100px;
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 1;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
}

.l-header__btn .digital-plan a .txt01:before {
	content: "";
	display: block;
	margin-right: 115px;
	width: 0;
	height: 0;
	border-style: solid;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	border-top: 5px solid #ffff00;
	border-bottom: 0;
	position: absolute;
	top: 100%;
	right: 0;
	z-index: 1;
}

.l-header__btn .digital-plan a .txt02 {
	display: flex;
	justify-content: center;
	align-items: center;
}

.l-header__btn .digital-plan a .txt02 .logo {
	display: block;
	width: 124px;
	margin-right: 12px;
}

.l-header__btn .digital-plan a .txt02 .logo img {
	display: block;
}

.l-header__btn .digital-plan a .txt02 .txt {
	display: block;
	color: #0c509f;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
}

.l-header__btn .entry {
	margin-right: 20px;
	font-weight: bold;
}

.l-header__btn .entry a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 54px;
	padding: 0 24px;
	color: #fff;
	border-radius: 100px;
	background-color: #ff9300;
}

.l-header__btn .entry a span {
	padding-left: 38px;
	position: relative;
	z-index: 1;
}

.l-header__btn .entry a span:before {
	content: "";
	display: block;
	width: 26px;
	height: 26px;
	background: url('/img/lp/icon_account01.png') no-repeat center;
	background-size: contain;
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 1;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}

.l-header__btn .line {
	font-weight: bold;
}

.l-header__btn .line a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 54px;
	padding: 0 24px;
	color: #fff;
	border-radius: 100px;
	background-color: #00ba00;
}

.l-header__btn .line a span {
	padding-left: 42px;
	position: relative;
	z-index: 1;
}

.l-header__btn .line a span:before {
	content: "";
	display: block;
	width: 30px;
	height: 26px;
	background: url('/img/lp/icon_line01.png') no-repeat center;
	background-size: contain;
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 1;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}

@media screen and (max-width:1299px){
	.l-header {
		height: 7.698vw;
	}

	.l-header__logo {
		width: 18.476vw;
		margin-left: 4.619vw;
	}

	.l-header__btn {
		margin-right: 4.619vw;
	}

	.l-header__btn .digital-plan {
		margin-right: 1.54vw;
	}

	.l-header__btn .digital-plan a {
		height: 4.157vw;
		padding: 0 1.848vw;
		border: 0.230769230769231vw solid #0c509f;
	}

	.l-header__btn .digital-plan a .txt01 {
		height: 2.309vw;
		padding: 0 0.924vw;
		margin-top: -2.002vw;
		font-size: 0.924vw;
	}

	.l-header__btn .digital-plan a .txt01:before {
		margin-right: 8.853vw;
		border-right: 0.384615384615385vw solid transparent;
		border-left: 0.384615384615385vw solid transparent;
		border-top: 0.384615384615385vw solid #ffff00;
	}

	.l-header__btn .digital-plan a .txt02 .logo {
		width: 9.546vw;
		margin-right: 0.924vw;
	}

	.l-header__btn .digital-plan a .txt02 .txt {
		font-size: 1.232vw;
	}

	.l-header__btn .entry {
		margin-right: 1.54vw;
		font-size: 1.232vw;
	}

	.l-header__btn .entry a {
		height: 4.157vw;
		padding: 0 1.848vw;
	}

	.l-header__btn .entry a span {
		padding-left: 2.925vw;
	}

	.l-header__btn .entry a span:before {
		width: 2.002vw;
		height: 2.002vw;
	}

	.l-header__btn .line {
		font-size: 1.232vw;
	}

	.l-header__btn .line a {
		height: 4.157vw;
		padding: 0 1.848vw;
	}

	.l-header__btn .line a span {
		padding-left: 3.233vw;
	}

	.l-header__btn .line a span:before {
		width: 2.309vw;
		height: 2.002vw;
	}
}

@media screen and (max-width:1099px){

	/*.l-header__logo {*/
		/*width: 200px;*/
		/*margin-left: 5.454545454545455vw;*/
	/*}*/

	/*.l-header__btn .entry {*/
		/*margin-right: 15px;*/
		/*font-size: 14px;*/
	/*}*/

	/*.l-header__btn .entry a {*/
		/*padding: 0 15px;*/
	/*}*/

	/*.l-header__btn {*/
		/*margin-right: 5.454545454545455vw;*/
	/*}*/

	/*.l-header__btn .line {*/
		/*font-size: 14px;*/
	/*}*/

	/*.l-header__btn .line a {*/
		/*padding: 0 15px;*/
	/*}*/
}

@media screen and (max-width:767px){
	.l-header {
		height: 55px;
	}

	.l-header__logo {
		width: 144px;
		margin-left: 4.666666666666667%;
	}

	.l-header__btn {
		margin-right: 4.666666666666667%;
	}

	.l-header__btn .digital-plan {
		margin-right: 0;
	}

	.l-header__btn .digital-plan a {
		height: 27px;
		padding: 0 7px;
		border: 2px solid #0c509f;
	}

	.l-header__btn .digital-plan a .txt01 {
		height: 15px;
		padding: 0 6px;
		margin-top: -13px;
		font-size: 6px;
	}

	.l-header__btn .digital-plan a .txt01:before {
		display: none;
	}

	.l-header__btn .digital-plan a .txt02 .logo {
		width: 62px;
		margin-right: 6px;
	}

	.l-header__btn .digital-plan a .txt02 .txt {
		font-size: 9px;
	}

	.l-header__btn .entry {
		display: none;
	}

	.l-header__btn .line {
		display: none;
	}
}

/**********************************

 l-fixed-contents

***********************************/
.l-fixed-contents {
	display: none;
}

@media screen and (max-width:767px){
	.l-fixed-contents {
		display: flex;
		width: 100%;
		height: 50px;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 10;
	}

	.l-fixed-contents .entry {
		width: 50%;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: normal;
	}

	.l-fixed-contents .entry a {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		color: #fff;
		background-color: #ff9300;
	}

	.l-fixed-contents .entry a span {
		padding-left: 23px;
		position: relative;
		z-index: 1;
	}

	.l-fixed-contents .entry a span:before {
		content: "";
		display: block;
		width: 16px;
		height: 16px;
		background: url('/img/lp/icon_account01.png') no-repeat center;
		background-size: contain;
		position: absolute;
		top: 50%;
		left: 0;
		z-index: 1;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}

	.l-fixed-contents .line {
		width: 50%;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: -.02em;
	}

	.l-fixed-contents .line a {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		color: #fff;
		background-color: #00ba00;
	}

	.l-fixed-contents .line a span {
		padding-left: 24px;
		position: relative;
		z-index: 1;
	}

	.l-fixed-contents .line a span:before {
		content: "";
		display: block;
		width: 18px;
		height: 16px;
		background: url('/img/lp/icon_line01.png') no-repeat center;
		background-size: contain;
		position: absolute;
		top: 50%;
		left: 0;
		z-index: 1;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}
}

/**********************************

 l-footer

***********************************/
.l-footer {

}

.l-footer__inner {
	padding: 30px 0;
}

.l-footer__inner-logo {
	margin-bottom: 25px;
	text-align: center;
}

.l-footer__inner-copy {
	color: #b8b8b8;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
}

@media screen and (max-width:767px){

	.l-footer__inner {
		padding: 20px 0;
	}

	.l-footer__inner-logo {
		width: 145px;
		margin: 0 auto 12px;
	}

	.l-footer__inner-copy {
		font-size: 9px;
	}

}

/**********************************

 c-bgGray

***********************************/
.c-bgGray {
	background-color: #fafafa;
}

/**********************************

 c-bgDot01

***********************************/
.c-bgDot01 {
	background: url('/img/lp/bg_dot01.png') repeat;
}

@media screen and (max-width:767px){
	.c-bgDot01 {
		background-size: 10px 10px;
	}
}

/**********************************

 c-title01

***********************************/
.c-title01 {
	margin-top: -.3em;
	margin-bottom: 50px;
	font-size: 42px;
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: .1em;
	text-align: center;
}

.c-title01.white {
	color: #fff;
	background: none;
}

@media screen and (max-width:1099px){
	.c-title01 {
		font-size: 3.818181818181818vw;
	}
}

@media screen and (max-width:767px){
	.c-title01 {
		margin-bottom: 25px;
		font-size: 21px;
	}
}

/**********************************

 c-title02

***********************************/
.c-title02 {
	padding: 9px 15px;
	color: #1daeec;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	background-color: #ffffff;
	border: 3px solid #1daeec;
	border-radius: 10px;
}

@media screen and (max-width:767px){
	.c-title02 {
		padding: 4px 15px;
		font-size: 16px;
		border: 2px solid #1daeec;
		border-radius: 6px;
	}
}

/**********************************

 c-subHero

***********************************/
.c-subHero {
	background-color: #d1ff03;
}

.c-subHero__inner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
}

.c-subHero__inner__ttl {
	color: #000;
	font-size: 36px;
	font-weight: bold;
	letter-spacing: .15em;
	line-height: 1.5;
	text-align: center;
}

@media screen and (max-width:767px){

	.c-subHero__inner {
		height: 150px;
	}

	.c-subHero__inner__ttl {
		font-size: 18px;
	}
}

/**********************************

 c-btn01

***********************************/
a.c-btn01 {
	display: inline-block;
	padding: 13px 20px;
	color: #1daeec;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	line-height: 1.5;
	border-radius: 100px;
	border: 2px solid #1daeec;
}

a.c-btn01 span {
	padding-right: 20px;
	position: relative;
	z-index: 1;
}

a.c-btn01 span:before {
	content: "";
	display: block;
	width: 6px;
	height: 10px;
	background: url('/img/lp/icon_arr02_pc.png') no-repeat center;
	background-size: contain;
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 1;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}


@media screen and (max-width:767px){
	a.c-btn01 {
		display: block;
		padding: 8px 17px;
		font-size: 14px;
	}

	a.c-btn01 span {
		display: block;
		padding-right: 0;
	}

	a.c-btn01 span:before {
		width: 6px;
		height: 10.5px;
		background: url('/img/lp/icon_arr02_sp.png') no-repeat center;
		background-size: contain;
	}
}

/**********************************

 c-form

***********************************/
.c-form {
}

.c-form__content {
	margin-bottom: 55px;
}

.c-form__content dl {
	display: table;
	width: 100%;
	margin-bottom: 34px;
}

.c-form__content dl dt {
	display: table-cell;
	width: 330px;
	padding: 15px 20px 15px 25px;
	vertical-align: middle;
	background-color: #0054b0;
	box-shadow:0px 4px 0px 0px #a9c1db;
}

.c-form__content dl dt .inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	line-height: 1.5;
}

.c-form__content dl dt .inner .name {
	color: #fff;
	font-size: 20px;
	font-weight: bold;
}

.c-form__content dl dt .inner .require {
	padding: 3px 5px;
	color: #ffff00;
	font-size: 12px;
	font-weight: bold;
	background-color: #ff3f00;
}

.c-form__content dl dt .inner .any {
	padding: 3px 5px;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	background-color: #00bfff;
}

.c-form__content dl dd {
	display: table-cell;
	padding: 19px;
	line-height: 1.5;
	vertical-align: middle;
	background-color: #fff;
	border-top: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	box-shadow:0px 4px 0px 0px #d9d9d9;
}

.c-form__submit {
	width: 340px;
	max-width: 100%;
	margin: 0 auto;
}

.c-form__submit button,
.c-form__confirmBtn__btn .submit {
	display: block;
	width: 100%;
	padding: 20px 15px;
	margin-bottom: 5px;
	color: #fff;
	font-size: 26px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	letter-spacing: .05em;
	line-height: 1.5;
	border-radius: 9px;
	box-shadow:0px 5px 0px 0px #802000;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff531a+0,f23d00+100 */
	background: rgb(255,83,26); /* Old browsers */
	background: -moz-linear-gradient(top,  rgba(255,83,26,1) 0%, rgba(242,61,0,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(255,83,26,1) 0%,rgba(242,61,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(255,83,26,1) 0%,rgba(242,61,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff531a', endColorstr='#f23d00',GradientType=0 ); /* IE6-9 */
	border: none;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.c-form__confirmBtn {
	display: flex;
	justify-content: center;
}

.c-form__confirmBtn__btn {
	width: 360px;
}

.c-form__confirmBtn__btn:first-child {
	margin-right: 30px;
}

.c-form__confirmBtn__btn .back {
	display: block;
	width: 100%;
	padding: 20px 15px;
	margin-bottom: 5px;
	color: #fff;
	font-size: 26px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	letter-spacing: .05em;
	line-height: 1.5;
	border-radius: 9px;
	box-shadow:0px 5px 0px 0px #333333;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#666666+0,777777+100 */
	background: rgb(102,102,102); /* Old browsers */
	background: -moz-linear-gradient(top,  rgba(102,102,102,1) 0%, rgba(119,119,119,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(102,102,102,1) 0%,rgba(119,119,119,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(102,102,102,1) 0%,rgba(119,119,119,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#666666', endColorstr='#777777',GradientType=0 ); /* IE6-9 */
	border: none;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

@media screen and (max-width:767px){

	.c-form__content {
		margin-bottom: 32px;
	}

	.c-form__content dl {
		display: block;
		width: auto;
		margin-bottom: 30px;
	}

	.c-form__content dl dt {
		display: block;
		width: auto;
		padding: 11px 15px;
		box-shadow: none;
	}

	.c-form__content dl dt .inner {
		display: block;
		width: auto;
		position: relative;
	}

	.c-form__content dl dt .inner .name {
		font-size: 16px;
		text-align: center;
	}

	.c-form__content dl dt .inner .require {
		padding: 3px 5px;
		font-size: 10px;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}

	.c-form__content dl dt .inner .any {
		padding: 3px 5px;
		font-size: 10px;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}

	.c-form__content dl dd {
		display: block;
		padding: 14px;
		border: 1px solid #dddddd;
		box-shadow: none;
	}

	.c-form__submit {
		width: 240px;
	}

	.c-form__submit button,
	.c-form__confirmBtn__btn .submit {
		padding: 12px 15px;
		margin-bottom: 4px;
		font-size: 17px;
		border-radius: 6px;
		box-shadow:0px 4px 0px 0px #802000;
	}

	.c-form__confirmBtn {
		justify-content: space-between;
	}

	.c-form__confirmBtn__btn {
		width: 47.5%;
	}

	.c-form__confirmBtn__btn:first-child {
		margin-right: 0;
	}

	.c-form__confirmBtn__btn .back {
		padding: 12px 15px;
		margin-bottom: 4px;
		font-size: 17px;
		border-radius: 6px;
		box-shadow:0px 4px 0px 0px #333333;
	}

}


/**********************************

 c-faq__inner-list

***********************************/
.c-faq__inner-list {
}

.c-faq__inner-list dl {
	margin-bottom: 63px;
	float: none;
}

.c-faq__inner-list dl:last-child {
	margin-bottom: 0;
}

.c-faq__inner-list dl dt {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 23px;
	float: none;
}

.c-faq__inner-list dl dt .icon {
	width: 80px;
	color: #fff;
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	line-height: 78px;
	background-color: #1daeec;
	border: 1px solid #1daeec;
	box-shadow: 0 3px 0 rgba(0,0,0,0.05);
	border-radius: 10px;
}

.c-faq__inner-list dl dt .ttl {
	flex: 1;
	min-height: 80px;
	padding: 24px 30px;
	margin-left: 20px;
	color: #0c509f;
	font-size: 20px;
	font-weight: bold;
	line-height: 1.5;
	border: 1px solid #eeeeee;
	background-color: #fff;
	box-shadow: 0 3px 0 rgba(0,0,0,0.05);
	border-radius: 10px;
}

.c-faq__inner-list dl dd {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.c-faq__inner-list dl dd .icon {
	width: 80px;
	color: #0c509f;
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	line-height: 78px;
	background-color: #fff;
	border: 1px solid #eeeeee;
	box-shadow: 0 3px 0 rgba(0,0,0,0.05);
	border-radius: 10px;
}

.c-faq__inner-list dl dd .cont {
	flex: 1;
	min-height: 80px;
	padding: 24px 30px;
	margin-left: 20px;
	border: 1px solid #eeeeee;
	background-color: #fff;
	box-shadow: 0 3px 0 rgba(0,0,0,0.05);
	border-radius: 10px;
}

.c-faq__inner-list dl dd .cont p {
	margin-bottom: 20px;
}

.c-faq__inner-list dl dd .cont p:last-child {
	margin-bottom: 0;
}

.c-faq__inner-list dl dd .cont .btn {
}

@media screen and (max-width:767px){

	.c-faq__inner-list {
	}

	.c-faq__inner-list dl {
		margin-bottom: 32px;
	}

	.c-faq__inner-list dl dt {
		margin-bottom: 17px;
	}

	.c-faq__inner-list dl dt .icon {
		width: 40px;
		font-size: 12px;
		line-height: 38px;
		border-radius: 6px;
		box-shadow: 0 2px 0 rgba(0,0,0,0.05);
	}

	.c-faq__inner-list dl dt .ttl {
		flex: 1;
		min-height: 40px;
		padding: 7px 10px;
		margin-left: 10px;
		font-size: 14px;
		border-radius: 6px;
		box-shadow: 0 2px 0 rgba(0,0,0,0.05);
	}

	.c-faq__inner-list dl dd .icon {
		width: 40px;
		font-size: 12px;
		line-height: 38px;
		border-radius: 6px;
		box-shadow: 0 2px 0 rgba(0,0,0,0.05);
	}

	.c-faq__inner-list dl dd .cont {
		min-height: 40px;
		padding: 7px 10px;
		margin-left: 10px;
		font-size: 14px;
		border-radius: 6px;
		box-shadow: 0 2px 0 rgba(0,0,0,0.05);
	}

	.c-faq__inner-list dl dd .cont p {
		margin-bottom: 10px;
	}

	.c-faq__inner-list dl dd .cont .btn {
		margin-bottom: 10px;
	}

}



/**********************************

 c-completeSec

***********************************/
.c-completeSec {
}

.c-completeSec__inner {
	padding: 120px 0;
}

.c-completeSec__inner .intro {
	margin-bottom: 60px;
	font-size: 18px;
	font-weight: bold;
}

.c-completeSec__inner .back {
	max-width: 430px;
	margin: 0 auto;
}


@media screen and (max-width:767px){

	.c-completeSec__inner {
		padding: 60px 0;
	}

	.c-completeSec__inner .intro {
		margin-bottom: 30px;
		font-size: 14px;
	}

	.c-completeSec__inner .back {
		max-width: 215px;
	}
}



/**********************************

 c-hero

***********************************/
.c-hero {
	background: url('/img/lp/hero_bg01_pc.png') no-repeat right top;
}

.c-hero__inner {
	width: 1300px;
	height: 880px;
	padding-top: 10px;
	padding-left: 50px;
}

.c-hero__inner-txt01 {
	margin-bottom: 25px;
	font-size: 30px;
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: .12em;
}

.c-hero__inner-txt01 .pcBlock {
	display: inline-block;
	padding: 10px 20px;
	background-color: #fff;
	border-radius: 10px;
}

.c-hero__inner-ttl {
	margin-bottom: 35px;
	color: #fff;
	font-size: 46px;
	font-weight: bold;
	line-height: 2.55;
	letter-spacing: .12em;
}

.c-hero__inner-ttl .block {
	padding: 14px 20px;
	background-color: #000000;
	border-radius: 10px;
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
}

.c-hero__inner-box-wrap {
	display: flex;
}

.c-hero__inner-list {
	display: flex;
	margin-bottom: 50px;
}

.c-hero__inner-list li {
	/*width: 240px;*/
	padding: 25px 20px 20px;
	margin-right: 20px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 3px 0px 0px rgba(0,0,0,.2);
	position: relative;
	z-index: 1;
}

.c-hero__inner-list li:last-child {
	margin-right: 0;
}

.c-hero__inner-list li .num {
	width: 28px;
	height: 28px;
	line-height: 28px;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
	letter-spacing: normal;
	background-color: #ff9300;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 1;
	transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
}

.c-hero__inner-list li .txt01 {
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	line-height: 1.6;
	white-space: nowrap;
	letter-spacing: .07em;
}

.c-hero__inner-box {
	width: auto;
	padding-top: 35px;
	padding-right: 20px;
	padding-bottom: 20px;
	margin-bottom: 55px;
	background-color: #fff;
	border-radius: 10px;
	white-space: nowrap;
	box-shadow: 0px 3px 0px 0px rgba(0,0,0,.2);
	position: relative;
	z-index: 1;
}

.c-hero__inner-box-ttl {
	padding: 8px 20px;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	line-height: 1.5;
	white-space: nowrap;
	background-color: #ff9300;
	border-radius: 6px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}

.c-hero__inner-box-ttl span {
	display: inline-block;
	letter-spacing: .03em;
}

.c-hero__inner-box-list {
	display: flex;
}

.c-hero__inner-box-list-sec {
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
	z-index: 1;
}

.c-hero__inner-box-list-sec:last-child {
	padding-right: 0;
}

.c-hero__inner-box-list-sec:nth-child(1) {
	padding-right: 15px;
	padding-left: 20px;
}

.c-hero__inner-box-list-sec:after {
	content: "";
	display: block;
	width: 1px;
	height: 100%;
	background-color: #cccccc;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1;
}

.c-hero__inner-box-list-sec:last-child:after {
	display: none;
}

.c-hero__inner-box-list-sec:nth-child(3) {
	padding-left: 30px;
	padding-right: 30px;
}

.c-hero__inner-box-list-sec .ttl {
	margin-bottom: 8px;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	position: relative;
	z-index: 1;
}

/*.c-hero__inner-box-list-sec .ttl:before {*/
	/*content: "";*/
	/*display: block;*/
	/*width: 100%;*/
	/*height: 1px;*/
	/*background-color: #cccccc;*/
	/*position: absolute;*/
	/*top: 50%;*/
	/*left: 50%;*/
	/*z-index: 1;*/
	/*transform: translateY(-50%) translateX(-50%);*/
	/*-webkit-transform: translateY(-50%) translateX(-50%);*/
/*}*/

.c-hero__inner-box-list-sec .ttl span {
	display: inline-block;
	padding: 0 .5em;
	background-color: #fff;
	position: relative;
	z-index: 2;
}

.c-hero__inner-box-list-sec p {
	margin: 0 -7.5%;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	line-height: 1;
}

.c-hero__inner-box-list-sec p .num {
	display: inline-block;
	margin-right: .1em;
	font-size: 24px;
	line-height: 1;
	transform: translateY(.05em);
}

.c-hero__inner-box-list-sec p .en {
	display: inline-block;
	margin: 0 0 0 .1em;
	color: #1daeec;
	font-size: 40px;
	line-height: 1;
	transform: translateY(.05em);
}

.c-hero__inner-box-list-sec p .tax {
	font-size: 12px;
}

.c-hero__inner-btn {
	width: 442px;
}

.c-hero__inner-btn a {
	display: block;
	padding: 20px 0;
	color: #fff;
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	border-radius: 100px;
	box-shadow: 0px 5px 0px 0px #0a1f00;
	overflow: visible;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffa428+0,f47a00+100 */
	background: rgb(255,164,40); /* Old browsers */
	background: -moz-linear-gradient(top,  rgba(255,164,40,1) 0%, rgba(244,122,0,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(255,164,40,1) 0%,rgba(244,122,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(255,164,40,1) 0%,rgba(244,122,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffa428', endColorstr='#f47a00',GradientType=0 ); /* IE6-9 */
	position: relative;
	z-index: 1;
}

.c-hero__inner-btn a:before {
	content: "";
	display: block;
	width: 98px;
	height: 98px;
	margin: -39px -100px 0 0;
	background: url('/img/lp/hero_balloon01_pc.png') no-repeat left top;
	background-size: contain;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
}

.c-hero__inner-btn a span {
	padding-left: 44px;
	position: relative;
	z-index: 1;
}

.c-hero__inner-btn a span:before {
	content: "";
	display: block;
	width: 32px;
	height: 32px;
	background: url('/img/lp/icon_account02.png') no-repeat left top;
	background-size: contain;
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 1;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}

@media screen and (max-width:1920px){

	.c-hero {
		background: url('/img/lp/hero_bg01_pc.png') no-repeat center top;
	}

}

@media screen and (max-width:1299px){

	.c-hero {
		background-size: cover;
	}

	.c-hero__inner {
		width: 100vw;
		height: 67.692vw;
		padding-top: 0.769vw;
		padding-left: 3.846vw;
	}

	.c-hero__inner-txt01 {
		margin-bottom: 1.923vw;
		font-size: 2.308vw;
	}

	.c-hero__inner-txt01 .pcBlock {
		padding: 0.769vw 1.538vw;
		border-radius: 0.769vw;
	}

	.c-hero__inner-ttl {
		margin-bottom: 2.692vw;
		font-size: 3.538vw;
	}

	.c-hero__inner-ttl .block {
		padding: 1.077vw 1.538vw;
		border-radius: 0.769vw;
	}

	.c-hero__inner-list {
		margin-bottom: 3.846vw;
	}

	.c-hero__inner-list li {
		padding: 1.923vw 1.538vw 1.538vw;
		margin-right: 1.538vw;
		border-radius: 0.769vw;
		box-shadow: 0px 0.230769230769231vw 0px 0px rgba(0,0,0,.2);
	}

	.c-hero__inner-list li .num {
		width: 2.154vw;
		height: 2.154vw;
		line-height: 2.154vw;
		font-size: 0.923vw;
	}

	.c-hero__inner-list li .txt01 {
		font-size: 1.385vw;
	}

	.c-hero__inner-box {
		padding-top: 2.692vw;
		padding-right: 1.538vw;
		padding-bottom: 1.538vw;
		margin-bottom: 4.231vw;
		border-radius: 0.769vw;
		box-shadow: 0px .23076923vw 0px 0px rgba(0,0,0,.2);
	}

	.c-hero__inner-box-ttl {
		padding: 0.615vw 1.538vw;
		font-size: 1.538vw;
		border-radius: 0.462vw;
	}

	.c-hero__inner-box-list-sec {
		padding-left: 1.154vw;
		padding-right: 1.154vw;
	}

	.c-hero__inner-box-list-sec:nth-child(1) {
		padding-right: 1.154vw;
		padding-left: 1.538vw;
	}

	.c-hero__inner-box-list-sec:after {
		width: 0.077vw;
	}

	.c-hero__inner-box-list-sec:nth-child(3) {
		padding-left: 2.308vw;
		padding-right: 2.308vw;
	}

	.c-hero__inner-box-list-sec .ttl {
		margin-bottom: 0.615vw;
		font-size: 1.077vw;
	}

	.c-hero__inner-box-list-sec p {
		font-size: 1.538vw;
	}

	.c-hero__inner-box-list-sec p .num {
		font-size: 1.846vw;
	}

	.c-hero__inner-box-list-sec p .en {
		font-size: 3.077vw;
	}

	.c-hero__inner-box-list-sec p .tax {
		font-size: 0.923vw;
	}

	.c-hero__inner-btn {
		width: 34vw;
	}

	.c-hero__inner-btn a {
		padding: 1.538vw 0;
		font-size: 1.692vw;
		box-shadow: 0px 0.384615384615385vw 0px 0px #0a1f00;
	}

	.c-hero__inner-btn a:before {
		width: 7.538vw;
		height: 7.538vw;
		margin: -3vw -7.692vw 0 0;
	}

	.c-hero__inner-btn a span {
		padding-left: 3.385vw;
	}

	.c-hero__inner-btn a span:before {
		width: 2.462vw;
		height: 2.462vw;
	}
}

@media screen and (max-width:767px){

	.c-hero {
		padding: 0 4.666666666666667%;
		background: url('/img/lp/hero_bg01_sp.png') no-repeat right top;
		background-size: contain;
		position: relative;
		z-index: 1;
	}

	.c-hero__inner {
		width: auto;
		height: 355.2vw;
		padding-top: 12vw;
		text-align: center;
	}

	.c-hero__inner-txt01 {
		margin-bottom: 4vw;
		font-size: 4vw;
		text-align: center;
	}

	.c-hero__inner-txt01 .pcBlock {
		padding: 1.333vw 2.667vw;
		border-radius: 1.6vw;
	}

	.c-hero__inner-ttl {
		margin-bottom: 8vw;
		font-size: 6.133vw;
		line-height: 2.45;
		text-align: center;
	}

	.c-hero__inner-ttl .block {
		padding: 1.867vw 2.667vw;
		border-radius: 1.6vw;
	}

	.c-hero__inner-list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 11.467vw;
	}

	.c-hero__inner-list li {
		width: 47.058823529411765%;
		padding: 4.2vw 0 3vw;
		margin-right: 0;
		border-radius: 1.333vw;
		box-shadow: 0px 0.4vw 0px 0px rgba(0,0,0,.2);
	}

	.c-hero__inner-list li:nth-child(3),
	.c-hero__inner-list li:nth-child(4),
	.c-hero__inner-list li:nth-child(5) {
		margin-top: 8.088235294117647%;
	}

	.c-hero__inner-list li:nth-child(5) {
		margin-left: 26.470588235294118%;
	}

	.c-hero__inner-list li .num {
		width: 5.867vw;
		height: 5.867vw;
		line-height: 5.867vw;
		font-size: 2.667vw;
	}

	.c-hero__inner-list li .txt01 {
		font-size: 3.733vw;
	}

	.c-hero__inner-box {
		width: 72vw;
		padding: 9.333333333333333vw 5.882352941176471% 6.666666666666667vw;
		margin: 0 auto;
		margin-bottom: 10.133vw;
		border-radius: 1.6vw;
		box-shadow: 0px 0.4vw 0px 0px rgba(0,0,0,.2);
	}

	.c-hero__inner-box-ttl {
		padding: 1.6vw 2.667vw;
		font-size: 3.733vw;
		border-radius: 1.6vw;
		position: absolute;
		top: 0;
		left: 50%;
		z-index: 1;
		transform: translateY(-50%) translateX(-50%);
		-webkit-transform: translateY(-50%) translateX(-50%);
	}

	.c-hero__inner-box-list {
		display: block;
	}

	.c-hero__inner-box-list-sec {
		width: auto;
		margin-bottom: 5.333vw;
	}

	.c-hero__inner-box-list-sec:nth-child(1) {
		padding-right: 0;
		padding-left: 0;
	}

	.c-hero__inner-box-list-sec:nth-child(2) {
		padding: 0;
	}

	.c-hero__inner-box-list-sec:before {
		display: none;
	}

	.c-hero__inner-box-list-sec:after {
		display: none;
	}

	.c-hero__inner-box-list-sec:nth-child(3) {
		padding-left: 0;
		padding-right: 0;
	}

	.c-hero__inner-box-list-sec:last-child {
		margin-bottom: 0;
	}

	.c-hero__inner-box-list-sec .ttl {
		margin-bottom: 1.333vw;
		font-size: 3.2vw;
	}

	.c-hero__inner-box-list-sec .ttl span {
		width: 45.333vw;
		padding: 0;
		text-align: center;
	}

	.c-hero__inner-box-list-sec p {
		margin: 0 -5%;
		font-size: 3.467vw;
	}

	.c-hero__inner-box-list-sec p .num {
		font-size: 4.267vw;
	}

	.c-hero__inner-box-list-sec p .en {
		font-size: 8.533vw;
	}

	.c-hero__inner-box-list-sec p .tax {
		font-size: 2.4vw;
	}

	.c-hero__inner-btn {
		width: 78.666666666666667vw;
		margin: 0 auto;
	}

	.c-hero__inner-btn a {
		padding: 3.4vw 0;
		font-size: 4vw;
		border-radius: 26.667vw;
		box-shadow: 0px 0.933333333333333vw 0px 0px #0a1f00;
	}

	.c-hero__inner-btn a:before {
		width: 14.667vw;
		height: 14.667vw;
		margin: -7vw -7.733vw 0 0;
		background: url('/img/lp/hero_balloon01_sp.png') no-repeat left top;
		background-size: contain;
	}

	.c-hero__inner-btn a span {
		padding-left: 6.933vw;
	}

	.c-hero__inner-btn a span:before {
		width: 4.933vw;
		height: 3.6vw;
	}
}



/**********************************

 c-before-after

***********************************/
.c-before-after {

}

.c-before-after__inner {
	padding: 30px 0;
}

.c-before-after__inner-gallery {
	height: 166px;
	background: url('/img/lp/before_after_pic_pc.png') repeat-x left center;
	animation: bg-slider 75s linear infinite;
}

@keyframes bg-slider {
	from { background-position: 0 center; }
	to { background-position: -2062px center; }
}

@media screen and (max-width:767px){

	.c-before-after__inner {
		padding: 20px 0;
	}

	.c-before-after__inner-gallery {
		height: 83px;
		background: url('/img/lp/before_after_pic_sp.png') repeat-x left center;
		background-size: 960px auto;
		animation: bg-slider 60s linear infinite;
	}

	@keyframes bg-slider {
		from { background-position: 0 center; }
		to { background-position: -960px center; }
	}
}



/**********************************

 c-ai

***********************************/
.c-ai {

}

.c-ai__inner {
	width: 1020px;
	margin: 0 auto;
	padding: 50px 0 55px;
}

.c-ai__inner-content {
	padding: 30px 30px 50px;
	border: 3px solid #1daeec;
	border-radius: 20px;
	position: relative;
	z-index: 1;
}

.c-ai__inner-content-box {
	display: flex;
	justify-content: center;
	align-items: center;
}

.c-ai__inner-content-box-left {
	margin-right: 30px;
}

.c-ai__inner-content-box-left .txt01 {
	font-size: 18px;
	font-weight: bold;
	letter-spacing: .15em;
}

.c-ai__inner-content-box-left .logo {
	width: 278px;
	margin-top: 15px;
}

.c-ai__inner-content-box-right {
	display: flex;
	align-items: center;
}

.c-ai__inner-content-box-right .sec {
	display: flex;
	align-items: center;
	margin-right: 25px;
}

.c-ai__inner-content-box-right .sec:last-child {
	margin-right: 0;
}

.c-ai__inner-content-box-right .sec .num {
	padding: 23px 6px;
	margin-right: 15px;
	color: #fff;
	font-size: 12px;
	font-weight: 900;
	text-align: center;
	line-height: 1.1;
	background: #ff9300;
	border-radius: 100px;
}

.c-ai__inner-content-box-right .sec .num .Oswald {
	display: inline-block;
	margin-top: 5px;
	font-size: 11px;
	font-weight: bold;
}

.c-ai__inner-content-box-right .sec .ttl {
	font-size: 18px;
	font-weight: bold;
	line-height: 1.5;
}

.c-ai__inner-content-box-right .sec .ttl .Oswald {
	font-size: 22px;
}

.c-ai__inner-content-btn {
	font-size: 22px;
	font-weight: bold;
	line-height: 1.5;
	white-space: nowrap;
	position: absolute;
	bottom: 0;
	left: 50%;
	z-index: 1;
	transform: translateY(50%) translateX(-50%);
	-webkit-transform: translateY(50%) translateX(-50%);
}

.c-ai__inner-content-btn a {
	display: block;
	padding: 14px 30px;
	color: #fff;
	border-radius: 100px;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#43bbef+0,1daeec+100 */
	background: linear-gradient(to bottom,  rgba(67,187,239,1) 0%,rgba(29,174,236,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	box-shadow: 0 3px 0 #0f7eae;
}

.c-ai__inner-content-btn a span {
	padding-right: 36px;
	position: relative;
	z-index: 1;
}

.c-ai__inner-content-btn a span:before {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	background: url('/img/lp/ai_arr01.png') no-repeat center / contain;
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 1;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}

@media screen and (max-width:1299px){

	.c-ai__inner {
		width: 78.462vw;
		padding: 3.846vw 0 4.231vw;
	}

	.c-ai__inner-content {
		padding: 2.308vw 2.308vw 3.846vw;
		border: .23076923vw solid #1daeec;
		border-radius: 1.538vw;
	}

	.c-ai__inner-content-box-left {
		margin-right: 2.308vw;
	}

	.c-ai__inner-content-box-left .txt01 {
		font-size: 1.385vw;
		letter-spacing: .15em;
	}

	.c-ai__inner-content-box-left .logo {
		width: 21.385vw;
		margin-top: 1.154vw;
	}

	.c-ai__inner-content-box-right .sec {
		margin-right: 1.923vw;
	}

	.c-ai__inner-content-box-right .sec .num {
		padding: 1.769vw 0.462vw;
		margin-right: 1.154vw;
		font-size: 0.923vw;
	}

	.c-ai__inner-content-box-right .sec .num .Oswald {
		margin-top: 0.385vw;
		font-size: 0.846vw;
	}

	.c-ai__inner-content-box-right .sec .ttl {
		font-size: 1.385vw;
	}

	.c-ai__inner-content-box-right .sec .ttl .Oswald {
		font-size: 1.692vw;
	}

	.c-ai__inner-content-btn {
		font-size: 1.692vw;
	}

	.c-ai__inner-content-btn a {
		padding: 1.077vw 2.308vw;
		box-shadow: 0 .23076923vw 0 #0f7eae;
	}

	.c-ai__inner-content-btn a span {
		padding-right: 2.769vw;
	}

	.c-ai__inner-content-btn a span:before {
		width: 1.538vw;
		height: 1.538vw;
	}
}

@media screen and (max-width:767px){

	.c-ai__inner {
		width: auto;
		padding: 25px 0 5px;
	}

	.c-ai__inner-content {
		padding: 15px 15px 25px;
		border: 2px solid #1daeec;
		border-radius: 15px;
	}

	.c-ai__inner-content-box {
		display: block;
	}

	.c-ai__inner-content-box-left {
		margin-right: 0;
	}

	.c-ai__inner-content-box-left .txt01 {
		font-size: 12px;
		text-align: center;
	}

	.c-ai__inner-content-box-left .logo {
		width: 167px;
		margin-top: 8px;
		margin-left: auto;
		margin-right: auto;
	}

	.c-ai__inner-content-box-right {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 10px;
		margin-bottom: -15px;
	}

	.c-ai__inner-content-box-right .sec {
		display: flex;
		align-items: center;
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 15px;
	}

	.c-ai__inner-content-box-right .sec:nth-child(2),
	.c-ai__inner-content-box-right .sec:last-child {
		margin-right: 10px;
	}

	.c-ai__inner-content-box-right .sec:last-child {
	}

	.c-ai__inner-content-box-right .sec .num {
		padding: 17px 5px;
		margin-right: 12px;
		font-size: 9px;
	}

	.c-ai__inner-content-box-right .sec .num .Oswald {
		margin-top: 5px;
		font-size: 9px;
	}

	.c-ai__inner-content-box-right .sec .ttl {
		font-size: 13.5px;
	}

	.c-ai__inner-content-box-right .sec .ttl .Oswald {
		font-size: 16.5px;
	}

	.c-ai__inner-content-btn {
		margin-top: 15px;
		font-size: 16px;
		text-align: center;
		white-space: normal;
		position: static;
		transform: none;
		-webkit-transform: none;
	}

	.c-ai__inner-content-btn a {
		display: inline-block;
		padding: 10px 20px 10px 30px;
		text-align: left;
		border-radius: 200px;
		box-shadow: 0 3px 0 #0f7eae;
	}

	.c-ai__inner-content-btn a span {
		display: inline-block;
		padding-right: 40px;
	}

	.c-ai__inner-content-btn a span:before {
		width: 20px;
		height: 20px;
		margin-right: 0;
		background: url('/img/lp/ai_arr02.png') no-repeat center / contain;
	}
}



/**********************************

 c-user

***********************************/
.c-user {
	padding-top: 30px;
	padding-bottom: 50px;
}

.c-user__head {
}

.c-user__head-inner {

}

.c-user__logo {

}

.c-user__logo-gallery01 {
	height: 36px;
	margin-bottom: 20px;
	background: url('/img/lp/user_logo01.png') repeat-x left center;
	animation: bg-logo-slider01 75s linear infinite reverse;
}

@keyframes bg-logo-slider01 {
	from { background-position: 0 center; }
	to { background-position: -1750px center; }
}

.c-user__logo-gallery02 {
	height: 58px;
	background: url('/img/lp/user_logo02.png') repeat-x left center;
	animation: bg-logo-slider02 38s linear infinite reverse;
}

@keyframes bg-logo-slider02 {
	from { background-position: 0 center; }
	to { background-position: -1754px center; }
}

.c-user__info {
	margin-top: 40px;
}

.c-user__info-inner {
	display: flex;
	justify-content: center;
	align-items: center;
}

.c-user__info-inner .pic {
	width: 340px;
	margin-right: 30px;
}

.c-user__info-inner .txt01 {
	font-size: 16px;
	font-weight: bold;
	line-height: 2.2;
}

@media screen and (max-width:767px){
	.c-user {
		padding-top: 10px;
		padding-bottom: 30px;
	}

	.c-user__head {
	}

	.c-user__head-inner {

	}

	.c-user__logo {

	}

	.c-user__logo-gallery01 {
		height: 18px;
		margin-bottom: 10px;
		animation: bg-logo-slider01 75s linear infinite reverse;
		background-size: 875px auto;
	}

	@keyframes bg-logo-slider01 {
		from { background-position: 0 center; }
		to { background-position: -875px center; }
	}

	.c-user__logo-gallery02 {
		height: 29px;
		animation: bg-logo-slider02 38s linear infinite reverse;
		background-size: 877px auto;
	}

	@keyframes bg-logo-slider02 {
		from { background-position: 0 center; }
		to { background-position: -877px center; }
	}

	.c-user__info {
		margin-top: 25px;
	}

	.c-user__info-inner {
		display: block;
	}

	.c-user__info-inner .pic {
		width: 255px;
		margin: 0 auto 20px;
	}

	.c-user__info-inner .txt01 {
		font-size: 12px;
		text-align: center;
	}
}


/**********************************

 c-about

***********************************/
.c-about {
	background: url('/img/lp/about_bg01_pc.png') no-repeat center bottom;
	background-size: cover;
}

.c-about__inner {
	padding: 70px 0 140px;
	position: relative;
	z-index: 1;
}

.c-about__inner:before {
	content: "HOME STAGING";
	display: block;
	margin-left: -65px;
	font-family: "YakuHanJP", 'Oswald', sans-serif;
	color: #2db4ee;
	font-size: 240px;
	font-weight: bold;
	line-height: .9;
	letter-spacing: .03em;
	white-space: nowrap;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.c-about__inner-content {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.c-about__inner-content .content {
	width: 47%;
	padding: 2.2% 3%;
	background-color: #fff;
	border-radius: 20px;
	box-shadow: 0px 3px 0px 0px rgba(0,0,0,.05);
}

.c-about__inner-content .content p {
	margin-bottom: .8em;
	font-weight: bold;
	line-height: 2;
}

.c-about__inner-content .content p:last-child {
	margin-bottom: 0;
}

.c-about__inner-content .pic {
	width: 47%;
}

@media screen and (max-width:1099px){

	.c-about__inner:before {
		margin-left: -5.909vw;
		font-size: 21.818vw;
	}

}

@media screen and (max-width:767px){

	.c-about {
		background: url('/img/lp/about_bg01_sp.png') no-repeat center bottom;
		background-size: cover;
	}

	.c-about__inner {
		padding: 35px 0 55px;
	}

	.c-about__inner:before {
		margin-left: -115px;
		font-size: 120px;
	}

	.c-about__inner-content {
		display: block;
	}

	.c-about__inner-content .content {
		width: auto;
		padding: 4% 5.882352941176471%;
		margin: 0 5.882352941176471% 27px;
		border-radius: 10px;
	}

	.c-about__inner-content .content p {
		margin-bottom: .8em;
		font-weight: bold;
		line-height: 2;
	}

	.c-about__inner-content .content p:last-child {
		margin-bottom: 0;
	}

	.c-about__inner-content .pic {
		width: 69.117647058823529%;
		margin: 0 auto;
		text-align: center;
	}
}



/**********************************

 c-reason

***********************************/
.c-reason {

}

.c-reason__inner {
	padding: 50px 0 50px;
}

.c-reason__inner-list {
	display: flex;
	justify-content: space-between;
	padding-top: 18px;
}

.c-reason__inner-list-sec {
	width: 30%;
	padding: 35px 3% 2.2%;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 3px 0 rgba(0,0,0,0.05);
	position: relative;
	z-index: 1;
}

.c-reason__inner-list-sec .num {
	width: 36px;
	height: 36px;
	line-height: 36px;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: normal;
	text-align: center;
	background-color: #1daeec;
	border-radius: 10px;
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 1;
	transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
}

.c-reason__inner-list-sec .pic {
	padding: 25px 15px;
	margin-bottom: 15px;
	background-color: #fafafa;
	border-radius: 10px;
}

.c-reason__inner-list-sec .pic img {
	display: block;
	width: 75px;
	margin: 0 auto;
}

.c-reason__inner-list-sec .ttl {
	padding-bottom: 15px;
	margin-bottom: 15px;
	font-size: 20px;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	border-bottom: 1px solid #e9e9e9;
}

.c-reason__inner-list-sec .txt01 {
}

@media screen and (max-width:767px){

	.c-reason__inner {
		padding: 35px 0 25px;
	}

	.c-reason__inner-list {
		display: block;
		padding-top: 14px;
	}

	.c-reason__inner-list-sec {
		width: auto;
		padding: 25px 4.411764705882353% 3.5%;
		margin-bottom: 47px;
		border-radius: 5px;
		box-shadow: 0 2px 0 rgba(0,0,0,0.05);
	}

	.c-reason__inner-list-sec:last-child {
		margin-bottom: 0;
	}

	.c-reason__inner-list-sec .num {
		width: 28px;
		height: 28px;
		line-height: 28px;
		font-size: 12px;
		border-radius: 5px;
	}

	.c-reason__inner-list-sec .pic {
		padding: 18px 15px;
		margin-bottom: 10px;
		border-radius: 5px;
	}

	.c-reason__inner-list-sec .pic img {
		width: 46px;
	}

	.c-reason__inner-list-sec .ttl {
		padding-bottom: 10px;
		margin-bottom: 10px;
		font-size: 16px;
	}

	.c-reason__inner-list-sec .txt01 {
	}

}



/**********************************

 c-case

***********************************/
.c-case {

}

.c-case__inner {
	padding: 50px 0 100px;
}

.c-case__inner-list {

}

.c-case__inner-list-sec {
	margin-bottom: 73px;
}

.c-case__inner-list-sec:last-child {
	margin-bottom: 0;
}

.c-case__inner-list-sec-ttl {
	padding: 15px 15px;
	margin-bottom: 40px;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	line-height: 1.5;
	background-color: #1daeec;
	border-radius: 10px;
}

.c-case__inner-list-sec-result {
	margin-bottom: 60px;
}

.c-case__inner-list-sec-result:last-child {
	margin-bottom: 0;
}

.c-case__inner-list-sec-result-box {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5%;
	position: relative;
	z-index: 1;
}

.c-case__inner-list-sec-result-box:last-child {
	margin-bottom: 0;
}

.c-case__inner-list-sec-result-box:before {
	content: "";
	display: block;
	width: 36px;
	height: 38px;
	background: url('/img/lp/case_arr01_pc.png') no-repeat center;
	background-size: contain;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: -1;
	transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
}

.c-case__inner-list-sec-result-box .before {
	width: 47%;
	position: relative;
	z-index: 1;
}

.c-case__inner-list-sec-result-box .before:before {
	content: "";
	display: block;
	width: 50px;
	height: 50px;
	background: url('/img/lp/case_before_pc.png') no-repeat left top;
	background-size: contain;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.c-case__inner-list-sec-result-box .after {
	width: 47%;
	position: relative;
	z-index: 1;
}

.c-case__inner-list-sec-result-box .after:before {
	content: "";
	display: block;
	width: 50px;
	height: 50px;
	background: url('/img/lp/case_after_pc.png') no-repeat left top;
	background-size: contain;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.c-case__inner-list-sec-comment {
	position: relative;
	z-index: 1;
}

.c-case__inner-list-sec-comment .inner {
	padding: 35px 3% 3%;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 3px 0 rgba(0,0,0,0.05);
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.c-case__inner-list-sec-comment .inner:before {
	content: "";
	display: block;
	width: 100%;
	height: 2px;
	background-color: #1daeec;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.c-case__inner-list-sec-comment .ttl {
	padding: 6px 15px;
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	white-space: nowrap;
	background-color: #1daeec;
	border-radius: 10px;
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 2;
	transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
}

.c-case__inner-list-btn {
	margin-top: 73px;
}

.c-case__inner-list-btn a {
	display: block;
	padding: 20px 75px;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	line-height: 1.5;
	background-color: #1daeec;
	border-radius: 10px;
	position: relative;
	z-index: 1;
}

.c-case__inner-list-btn a:before {
	content: "";
	display: block;
	width: 30px;
	height: 30px;
	background: url('/img/lp/icon_arr01_pc.png') no-repeat center;
	background-size: contain;
	position: absolute;
	top: 50%;
	right: 30px;
	z-index: 1;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transition: all 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.c-case__inner-list-btn.is-active a:before {
	transform: translateY(-50%) rotate(180deg);
	-webkit-transform: translateY(-50%) rotate(180deg);
}

.c-case__inner-list-btn a .txt01 {
	display: block;
	text-align: center;
	transition: opacity 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.c-case__inner-list-btn.is-active a .txt01 {
	opacity: 0;
}

.c-case__inner-list-btn a .txt02 {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
	opacity: 0;
	transition: opacity 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.c-case__inner-list-btn.is-active a .txt02 {
	opacity: 1;
}

.c-case__inner-list-more {
	display: none;
}

@media screen and (max-width:1099px){

	.c-case__inner-list-sec-result-box:before {
		width: 3.273vw;
		height: 3.455vw;
	}

	.c-case__inner-list-sec-result-box .before:before {
		width: 4.545454545454545vw;
		height: 4.545454545454545vw;
	}

	.c-case__inner-list-sec-result-box .after:before {
		width: 4.545454545454545vw;
		height: 4.545454545454545vw;
	}

}

@media screen and (max-width:767px){

	.c-case__inner {
		padding: 25px 0 40px;
	}

	.c-case__inner-list {

	}

	.c-case__inner-list-sec {
		margin-bottom: 37px;
	}

	.c-case__inner-list-sec-ttl {
		padding: 8px 15px;
		margin-bottom: 25px;
		font-size: 16px;
		border-radius: 8px;
	}

	.c-case__inner-list-sec-result {
		margin-bottom: 40px;
	}

	.c-case__inner-list-sec-result-box {
		display: block;
		width: 69.117647058823529%;
		margin: 0 auto 25px;
	}

	.c-case__inner-list-sec-result-box:before {
		width: 29px;
		height: 27px;
		background: url('/img/lp/case_arr01_sp.png') no-repeat center;
		background-size: contain;
	}

	.c-case__inner-list-sec-result-box .before {
		width: auto;
		margin-bottom: 48px;
		text-align: center;
	}

	.c-case__inner-list-sec-result-box .before:before {
		width: 32px;
		height: 32px;
		background: url('/img/lp/case_before_sp.png') no-repeat left top;
		background-size: contain;
	}

	.c-case__inner-list-sec-result-box .after {
		width: auto;
		text-align: center;
	}

	.c-case__inner-list-sec-result-box .after:before {
		width: 32px;
		height: 32px;
		background: url('/img/lp/case_after_sp.png') no-repeat left top;
		background-size: contain;
	}

	.c-case__inner-list-sec-comment {
		position: relative;
		z-index: 1;
	}

	.c-case__inner-list-sec-comment .inner {
		padding: 32px 4.411764705882353% 4.411764705882353%;
		border-radius: 8px;
		box-shadow: 0 3px 0 rgba(0,0,0,0.05);
		overflow: hidden;
	}

	.c-case__inner-list-sec-comment .ttl {
		padding: 4px 12px;
		font-size: 14px;
		border-radius: 6px;
	}

	.c-case__inner-list-btn {
		margin-top: 37px;
	}

	.c-case__inner-list-btn a {
		padding: 12px 45px;
		font-size: 14px;
		border-radius: 6px;
	}

	.c-case__inner-list-btn a:before {
		width: 20px;
		height: 20px;
		background: url('/img/lp/icon_arr01_sp.png') no-repeat center;
		background-size: contain;
		right: 15px;
	}

}



/**********************************

 c-effect

***********************************/
.c-effect {
	background-color: #1daeec;
}

.c-effect__inner {
	padding: 90px 0 103px;
}

.c-effect__inner-txt01 {
	margin-bottom: 55px;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	line-height: 1.5;
}

.c-effect__inner-content {
	display: flex;
	flex-wrap: wrap;
	padding: 4.5%;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 3px 0 rgba(0,0,0,0.05);
}

.c-effect__inner-content-sec {
	padding: 0 12px;
	margin-bottom: 100px;
	position: relative;
	z-index: 1;
}

.c-effect__inner-content-sec:nth-child(1),
.c-effect__inner-content-sec:nth-child(2),
.c-effect__inner-content-sec:nth-child(4),
.c-effect__inner-content-sec:nth-child(6) {
	border-right: 1px solid #eeeeee;
}

.c-effect__inner-content-sec:nth-child(1),
.c-effect__inner-content-sec:nth-child(2),
.c-effect__inner-content-sec:nth-child(3) {
	width: 33.333334%;
}

.c-effect__inner-content-sec:nth-child(4),
.c-effect__inner-content-sec:nth-child(5),
.c-effect__inner-content-sec:nth-child(6),
.c-effect__inner-content-sec:nth-child(7) {
	width: 50%;
}

.c-effect__inner-content-sec:nth-child(4),
.c-effect__inner-content-sec:nth-child(6) {
	padding-left: 9.340659340659341%;
}

.c-effect__inner-content-sec:nth-child(5),
.c-effect__inner-content-sec:nth-child(7) {
	padding-right: 9.340659340659341%;
}

.c-effect__inner-content-sec:nth-child(6),
.c-effect__inner-content-sec:nth-child(7) {
	margin-bottom: 50px;
}

.c-effect__inner-content-sec:before {
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	background-color: #eeeeee;
	position: absolute;
	bottom: -50px;
	left: 0;
	z-index: -1;
}

.c-effect__inner-content-sec .ttl {
	width: 242px;
	max-width: 100%;
	padding: 12px 10px;
	margin: 0 auto 15px;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	line-height: 1.5;
	background-color: #0c509f;
	border-radius: 10px;
}

.c-effect__inner-content-sec .txt01 {
	margin-bottom: 20px;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	line-height: 1.5;
}

.c-effect__inner-content-sec .pic {
	width: 242px;
	max-width: 100%;
	margin: 0 auto 20px;
	text-align: center;
}

.c-effect__inner-content-sec table {
	border-collapse: collapse;
	margin: 0 auto;
}

.c-effect__inner-content-sec table tr td {
	padding: 6px 10px;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.5;
}

.c-effect__inner-content-sec table tr td .color {
	display: inline-block;
	width: 1em;
	height: 1em;
	margin-right: .4em;
	border-radius: 3px;
	transform: translateY(.1em);
	-webkit-transform: translateY(.1em);
}

.c-effect__inner-content-sec table tr td .color1 {
	background-color: #0c509f;
}

.c-effect__inner-content-sec table tr td .color2 {
	background-color: #1daeec;
}

.c-effect__inner-content-sec table tr td .color3 {
	background-color: #c7e7ff;
}

.c-effect__inner-content-sec table tr td .color4 {
	background-color: #eeeeee;
}

.c-effect__inner-content-sec .txt02 {
	margin-top: 20px;
	margin-bottom: 15px;
	color: #0c509f;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
}

.c-effect__inner-content-sec .num {
	color: #0c509f;
	font-size: 32px;
	font-weight: bold;
	line-height: 1;
	text-align: center;
}

.c-effect__inner-content-sec .num .large {
	font-size: 48px;
}

@media screen and (max-width:1099px){

	.c-effect__inner-content-sec {
		padding: 0 1.091vw;
		margin-bottom: 9.091vw;
	}

	.c-effect__inner-content-sec:nth-child(6),
	.c-effect__inner-content-sec:nth-child(7) {
		margin-bottom: 4.545vw;
	}

	.c-effect__inner-content-sec:before {
		bottom: -4.545vw;
	}

	.c-effect__inner-content-sec .ttl {
		width: 22vw;
		padding: 1.091vw 0.909vw;
		margin: 0 auto 15px;
		font-size: 1.636vw;
		border-radius: 0.909vw;
	}

	.c-effect__inner-content-sec .txt01 {
		margin-bottom: 1.818vw;
		font-size: 1.636vw;
	}

	.c-effect__inner-content-sec .pic {
		width: 22vw;
		margin: 0 auto 20px;
	}

	.c-effect__inner-content-sec table tr td {
		padding: 0.545vw 0.909vw;
		font-size: 1.273vw;
	}

	.c-effect__inner-content-sec .txt02 {
		margin-top: 1.818vw;
		margin-bottom: 1.364vw;
		font-size: 1.636vw;
	}

	.c-effect__inner-content-sec .num {
		font-size: 2.909vw;
	}

	.c-effect__inner-content-sec .num .large {
		font-size: 4.364vw;
	}

}

@media screen and (max-width:767px){

	.c-effect__inner {
		padding: 35px 0 42px;
	}

	.c-effect__inner-txt01 {
		margin-bottom: 30px;
		font-size: 14px;
		text-align: left;
		line-height: 1.8;
	}

	.c-effect__inner-content {
		display: flex;
		flex-wrap: wrap;
		padding: 6.617647058823529% 4.411764705882353%;
		border-radius: 6px;
		box-shadow: 0 2px 0 rgba(0,0,0,0.05);
	}

	.c-effect__inner-content-sec {
		padding: 0 15px;
		margin-bottom: 50px;
	}

	.c-effect__inner-content-sec:nth-child(2),
	.c-effect__inner-content-sec:nth-child(4) {
		border-right: none;
	}

	.c-effect__inner-content-sec:nth-child(1),
	.c-effect__inner-content-sec:nth-child(3),
	.c-effect__inner-content-sec:nth-child(6) {
		border-right: 1px solid #eeeeee;
	}

	.c-effect__inner-content-sec:nth-child(1),
	.c-effect__inner-content-sec:nth-child(2),
	.c-effect__inner-content-sec:nth-child(3),
	.c-effect__inner-content-sec:nth-child(4),
	.c-effect__inner-content-sec:nth-child(4),
	.c-effect__inner-content-sec:nth-child(6),
	.c-effect__inner-content-sec:nth-child(7) {
		width: 50%;
	}

	.c-effect__inner-content-sec:nth-child(5) {
		width: 100%;
	}

	.c-effect__inner-content-sec:nth-child(4),
	.c-effect__inner-content-sec:nth-child(6) {
		padding: 0 15px;
	}

	.c-effect__inner-content-sec:nth-child(5),
	.c-effect__inner-content-sec:nth-child(7) {
		padding: 0 15px;
	}

	.c-effect__inner-content-sec:nth-child(6),
	.c-effect__inner-content-sec:nth-child(7) {
		margin-bottom: 25px;
	}

	.c-effect__inner-content-sec:before {
		bottom: -25px;
	}

	.c-effect__inner-content-sec .ttl {
		width: 121px;
		max-width: 100%;
		padding: 7px 10px;
		margin: 0 auto 7px;
		font-size: 14px;
		border-radius: 6px;
	}

	.c-effect__inner-content-sec .txt01 {
		margin-bottom: 12px;
		font-size: 12px;
	}

	.c-effect__inner-content-sec .pic {
		width: 121px;
		margin: 0 auto 10px;
	}

	.c-effect__inner-content-sec table {
		border-collapse: collapse;
		margin: 0 auto;
	}

	.c-effect__inner-content-sec table tr {
		display: block;
	}

	.c-effect__inner-content-sec table tr td {
		display: block;
		padding: 4px 0;
		font-size: 9px;
	}

	.c-effect__inner-content-sec .txt02 {
		margin-top: 10px;
		margin-bottom: 8px;
		font-size: 12px;
	}

	.c-effect__inner-content-sec .num {
		font-size: 16px;
	}

	.c-effect__inner-content-sec .num .large {
		font-size: 24px;
	}
}



/**********************************

 c-price

***********************************/
.c-price {
	background-color: #fafafa;
}

.c-price__inner {
	padding: 90px 0 130px;
}

.c-price__inner-table {
	margin-bottom: 95px;
}

.c-price__inner-table:last-child {
	margin-bottom: 0;
}

.c-price__inner table {
	width: 100%;
	margin-top: 50px;
	border-spacing: 0;
	line-height: 1.5;
}

.c-price__inner table thead tr th {
	width: 20%;
	padding: 0;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	line-height: 70px;
	background-color: #119bd7;
}

.c-price__inner table thead tr th:nth-child(3),
.c-price__inner table thead tr th:nth-child(5) {
	background-color: #0f89bd;
}

.c-price__inner table thead tr th.our {
	background-color: #ffffff;
	border-left: 6px solid #1daeec;
	border-right: 6px solid #1daeec;
	border-bottom: 1px solid #dddddd;
}

.c-price__inner table thead tr th:first-child {
	border-radius: 10px 0 0 0;
}

.c-price__inner table thead tr th:last-child {
	border-radius: 0 10px 0 0;
}

.c-price__inner table thead tr th .txt01 {
	text-align: center;
	position: relative;
	z-index: 1;
}

.c-price__inner table thead tr th .txt01:before {
	content: "";
	display: block;
	width: 100%;
	height: 14px;
	background-color: #fff;
	border-top: 6px solid #1daeec;
	border-left: 6px solid #1daeec;
	border-right: 6px solid #1daeec;
	border-radius: 10px 10px 0 0;
	position: absolute;
	bottom: 100%;
	left: -6px;
	z-index: 1;
	box-sizing: initial;
}

.c-price__inner table thead tr th .txt01 span {
	display: inline-block;
	padding: 0 16px;
	transform: translateY(-.4em);
	-webkit-transform: translateY(-.4em);
}

.c-price__inner table tbody tr th {
	padding: 0 5px;
	font-weight: bold;
	text-align: center;
	line-height: 80px;
	background-color: #ecf8fd;
	border-bottom: 1px solid #dddddd;
	border-left: 3px solid #dddddd;
}

.c-price__inner table tbody tr:last-child th {
	border-bottom: 3px solid #dddddd;
	border-radius: 0 0 0 10px;
}

.c-price__inner table tbody tr td {
	padding: 15px 5px;
	font-weight: bold;
	text-align: center;
	background-color: #ffffff;
	border-bottom: 1px solid #dddddd;
}

.c-price__inner table tbody tr td:last-child {
	border-right: 3px solid #dddddd;
}

.c-price__inner table tbody tr:last-child td {
	border-bottom: 3px solid #dddddd;
}

.c-price__inner table tbody tr:last-child td:last-child {
	border-radius: 0 0 10px 0;
}

.c-price__inner table tbody tr:nth-child(even) td {
	background-color: #fff;
}

.c-price__inner table tbody tr td.our {
	color: #0c509f;
	background-color: #ffffff;
	border-left: 6px solid #1daeec;
	border-right: 6px solid #1daeec;
	border-right: 6px solid #1daeec;
}

.c-price__inner table tbody tr td.our.large {
	font-size: 20px;
}

.c-price__inner table tbody tr td.our.large .num {
	margin: 0 .1em;
	font-size: 36px;
}

.c-price__inner table tbody tr:last-child td.our {
	padding: 0;
	text-align: center;
	position: relative;
	z-index: 1;
}

.c-price__inner table tbody tr td .small {
	display: inline-block;
	font-size: 12px;
}

.c-price__inner table tbody tr td.our .txt01 {
	line-height: 80px;
	text-align: center;
	position: relative;
	z-index: 1;
}

.c-price__inner table tbody tr td.our .txt01:before {
	content: "";
	display: block;
	width: 100%;
	height: 24px;
	margin-left: -6px;
	background-color: #fff;
	border-left: 6px solid #1daeec;
	border-right: 6px solid #1daeec;
	border-bottom: 6px solid #1daeec;
	box-shadow: 0px 5px 0px 0px rgba(0,0,0,.1);
	border-radius: 0 0 10px 10px;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	box-sizing: initial;
}

.c-price__inner table tbody tr td.our .txt01 span {
	display: inline-block;
	line-height: 1.5;
	transform: translateY(1.2em);
	-webkit-transform: translateY(1.2em);
}

@media screen and (max-width:1023px){

	.c-price__inner table thead tr th {
		font-size: 1.758vw;
		line-height: 6.836vw;
	}

	.c-price__inner table thead tr th.our {
		font-size: 2.344vw;
		border-left: 0.5859375vw solid #1daeec;
		border-right: 0.5859375vw solid #1daeec;
	}

	.c-price__inner table thead tr th .txt01:before {
		height: 1.3671875vw;
		border-top: 0.5859375vw solid #1daeec;
		border-left: 0.5859375vw solid #1daeec;
		border-right: 0.5859375vw solid #1daeec;
		border-radius: 10px 10px 0 0;
		left: -0.5859375vw;
	}

	.c-price__inner table thead tr th .txt01 span {
		padding: 0 1.5625vw;
	}

	.c-price__inner table thead tr th:first-child {
		border-radius: 0.977vw 0 0 0;
	}

	.c-price__inner table thead tr th:last-child {
		border-radius: 0 0.977vw 0 0;
	}

	.c-price__inner table thead tr th .txt01:before {
		height: 1.953vw;
		border-radius: 0.977vw 0.977vw 0 0;
	}

	.c-price__inner table tbody tr th {
		padding: 0 0.488vw;
		font-size: 1.5625vw;
		line-height: 7.813vw;
		border-left: 3px solid #000000;
	}

	.c-price__inner table tbody tr:last-child th {
		border-bottom: 3px solid #000000;
		border-radius: 0 0 0 0.977vw;
	}

	.c-price__inner table tbody tr td {
		padding: 1.465vw 0.488vw;
		font-size: 1.5625vw;
	}

	.c-price__inner table tbody tr td:last-child {
		border-right: 3px solid #000000;
	}

	.c-price__inner table tbody tr:last-child td {
		border-bottom: 3px solid #000000;
	}

	.c-price__inner table tbody tr:last-child td:last-child {
		border-radius: 0 0 0.977vw 0;
	}

	.c-price__inner table tbody tr td.our {
		font-size: 1.5625vw;
		border-left: 0.5859375vw solid #1daeec;
		border-right: 0.5859375vw solid #1daeec;
		border-right: 0.5859375vw solid #1daeec;
	}

	.c-price__inner table tbody tr td.our.large {
		font-size: 1.953vw;
	}

	.c-price__inner table tbody tr td.our.large .num {
		font-size: 3.516vw;
	}

	.c-price__inner table tbody tr td .small {
		font-size: 1.171875vw;
	}

	.c-price__inner table tbody tr td.our .txt01 {
		line-height: 7.813vw;
	}

	.c-price__inner table tbody tr td.our .txt01:before {
		height: 2.34375vw;
		margin-left: -0.5859375vw;
		border-left: 0.5859375vw solid #1daeec;
		border-right: 0.5859375vw solid #1daeec;
		border-bottom: 0.5859375vw solid #1daeec;
	}

}

@media screen and (max-width:767px){

	.c-price__inner {
		padding: 35px 0 57px;
	}

	.c-price__inner-table {
		margin: 0 -5.147058823529412% 45px;
	}

	.c-price__inner-table .c-title02 {
		margin: 0 4.666666666666667%;
	}

	.c-price__inner table {
		margin-top: 40px;
	}

	.c-price__inner table thead tr th {
		font-size: 11px;
		line-height: 35px;
	}

	.c-price__inner table thead tr th.our {
		border-left: 3px solid #1daeec;
		border-right: 3px solid #1daeec;
	}

	.c-price__inner table thead tr th:first-child {
		border-radius: 5px 0 0 0;
	}

	.c-price__inner table thead tr th:last-child {
		border-radius: 0;
	}

	.c-price__inner table thead tr th .txt01 {
		text-align: center;
		position: relative;
		z-index: 1;
	}

	.c-price__inner table thead tr th .txt01:before {
		height: 8px;
		border-top: 3px solid #1daeec;
		border-left: 3px solid #1daeec;
		border-right: 3px solid #1daeec;
		border-radius: 5px 5px 0 0;
		left: -3px;
	}

	.c-price__inner table thead tr th .txt01 span {
		padding: 0 8px;
		transform: translateY(-.4em);
		-webkit-transform: translateY(-.4em);
	}

	.c-price__inner table thead tr th .txt01 span img {
		width: 54.5px;
		max-width: 100%;
	}

	.c-price__inner table tbody tr th {
		padding: 0 5px;
		font-size: 9px;
		line-height: 1.5;
		border-left: none;
	}

	.c-price__inner table tbody tr:last-child th {
		border-bottom: 2px solid #dddddd;
		border-radius: 0;
	}

	.c-price__inner table tbody tr td {
		padding: 8px 2px;
		font-size: 9px;
		letter-spacing: -.02em;
	}

	.c-price__inner table tbody tr td:last-child {
		border-right: none;
	}

	.c-price__inner table tbody tr:last-child td {
		border-bottom: 2px solid #dddddd;
	}

	.c-price__inner table tbody tr:last-child td:last-child {
		border-radius: 0;
	}

	.c-price__inner table tbody tr td.our {
		font-size: 9px;
		border-left: 3px solid #1daeec;
		border-right: 3px solid #1daeec;
		border-right: 3px solid #1daeec;
	}

	.c-price__inner table tbody tr td.our.large {
		font-size: 10px;
	}

	.c-price__inner table tbody tr td.our.large .num {
		margin: 0 .1em 0 0;
		font-size: 18px;
	}

	.c-price__inner table tbody tr:last-child td.our {
		padding: 0;
		text-align: center;
		position: relative;
		z-index: 1;
	}

	.c-price__inner table tbody tr td .small {
		display: inline-block;
		font-size: 9px;
	}

	.c-price__inner table tbody tr td.our .txt01 {
		line-height: 40px;
		text-align: center;
		position: relative;
		z-index: 1;
	}

	.c-price__inner table tbody tr td.our .txt01:before {
		height: 10px;
		margin-left: -3px;
		border-left: 3px solid #1daeec;
		border-right: 3px solid #1daeec;
		border-bottom: 3px solid #1daeec;
		box-shadow: 0px 3px 0px 0px rgba(0,0,0,.1);
		border-radius: 0 0 5px 5px;
	}

	.c-price__inner table tbody tr td.our .txt01 span {
		display: inline-block;
		line-height: 1.5;
		transform: translateY(1.4em);
		-webkit-transform: translateY(1.4em);
	}
}



/**********************************

 c-cv

***********************************/
.c-cv {
	background: url('/img/lp/cv_bg01_pc.png') no-repeat center;
	background-size: cover;
}

.c-cv__inner {
	padding: 35px 0 60px;
	position: relative;
	z-index: 1;
}

.c-cv__inner:before {
	content: "";
	display: block;
	width: 1358px;
	height: 272px;
	background: url('/img/lp/cv_bg02_pc.png') no-repeat center bottom;
	background-size: contain;
	position: absolute;
	bottom: 0;
	left: 50%;
	z-index: -1;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
}

.c-cv__inner-ttl {
	margin-bottom: 30px;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
}

.c-cv__inner-btn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.c-cv__inner-btn .entry {
	width: 360px;
	margin-right: 40px;
	font-size: 20px;
	font-weight: bold;
}

.c-cv__inner-btn .entry a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80px;
	padding: 0 15px;
	color: #fff;
	border-radius: 100px;
	background-color: #ff9300;
}

.c-cv__inner-btn .entry a span {
	padding-left: 38px;
	position: relative;
	z-index: 1;
}

.c-cv__inner-btn .entry a span:before {
	content: "";
	display: block;
	width: 26px;
	height: 26px;
	background: url('/img/lp/icon_account01.png') no-repeat center;
	background-size: contain;
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 1;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}

.c-cv__inner-btn .line {
	width: 360px;
	font-size: 20px;
	font-weight: bold;
}

.c-cv__inner-btn .line a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80px;
	padding: 0 15px;
	color: #fff;
	border-radius: 100px;
	background-color: #00ba00;
}

.c-cv__inner-btn .line a span {
	padding-left: 42px;
	position: relative;
	z-index: 1;
}

.c-cv__inner-btn .line a span:before {
	content: "";
	display: block;
	width: 30px;
	height: 26px;
	background: url('/img/lp/icon_line01.png') no-repeat center;
	background-size: contain;
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 1;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}

@media screen and (max-width:767px){

	.c-cv {
		background: url('/img/lp/cv_bg01_sp.png') no-repeat center;
		background-size: cover;
	}

	.c-cv__inner {
		padding: 20px 0 0;
	}

	.c-cv__inner:before {
		display: none;
	}

	.c-cv__inner:after {
		content: "";
		display: block;
		width: 207px;
		height: 82px;
		margin: 15px auto 0;
		background: url('/img/lp/cv_bg02_sp.png') no-repeat center bottom;
		background-size: contain;
	}

	.c-cv__inner-ttl {
		margin-bottom: 20px;
		font-size: 14px;
	}

	.c-cv__inner-btn {
		display: block;
	}

	.c-cv__inner-btn .entry {
		width: 280px;
		margin: 0 auto 20px;
		font-size: 16px;
	}

	.c-cv__inner-btn .entry a {
		height: 50px;
		padding: 0 15px;
	}

	.c-cv__inner-btn .entry a span {
		padding-left: 23px;
	}

	.c-cv__inner-btn .entry a span:before {
		width: 16px;
		height: 16px;
	}

	.c-cv__inner-btn .line {
		width: 280px;
		margin: 0 auto;
		font-size: 16px;
	}

	.c-cv__inner-btn .line a {
		height: 50px;
		padding: 0 15px;
	}

	.c-cv__inner-btn .line a span {
		padding-left: 25px;
	}

	.c-cv__inner-btn .line a span:before {
		width: 18px;
		height: 16px;
	}
}



/**********************************

 c-flow

***********************************/
.c-flow {
	background-color: #fafafa;
}

.c-flow__inner {
	padding: 90px 0 103px;
}

.c-flow__inner-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: 18px;
	margin-bottom: -8.3%;
}

.c-flow__inner-list-sec {
	width: 16%;
	padding: 30px 2% 2%;
	margin-right: 2%;
	margin-bottom: 8.3%;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 3px 0 rgba(0,0,0,0.05);
	position: relative;
	z-index: 1;
}

.c-flow__inner-list-sec:nth-child(5),
.c-flow__inner-list-sec:nth-child(10) {
	margin-right: 0;
}

.c-flow__inner-list-sec:nth-child(6) {
	/*margin-left: 10.5%;*/
}

.c-flow__inner-list-sec:nth-child(9) {
	/*margin-right: 10.5%;*/
}

.c-flow__inner-list-sec:before {
	content: "";
	display: block;
	width: 11px;
	height: 22px;
	background: url('/img/lp/flow_arr01.png') no-repeat center;
	background-size: contain;
	position: absolute;
	top: 50%;
	right: -31px;
	z-index: 1;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}

.c-flow__inner-list-sec:nth-child(5):before,
.c-flow__inner-list-sec:nth-child(10):before {
	display: none;
}

.c-flow__inner-list-sec .num {
	width: 36px;
	height: 36px;
	line-height: 36px;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: normal;
	text-align: center;
	background-color: #1daeec;
	border-radius: 10px;
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 1;
	transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
}

.c-flow__inner-list-sec .pic {
	padding: 15px 15px;
	margin-bottom: 10px;
	background-color: #fafafa;
	border-radius: 10px;
}

.c-flow__inner-list-sec .pic img {
	display: block;
	width: 50px;
	margin: 0 auto;
}

.c-flow__inner-list-sec .ttl {
	font-size: 14px;
	font-weight: bold;
	text-align: center;
}

@media screen and (max-width:1099px){

	.c-flow__inner-list-sec:before {
		width: 1vw;
		height: 2vw;
		top: 50%;
		right: -2.818vw;
	}

}

@media screen and (max-width:767px){

	.c-flow__inner {
		padding: 35px 0 42px;
	}

	.c-flow__inner-list {
		padding-top: 14px;
		margin-bottom: -45px;
	}

	.c-flow__inner-list-sec {
		width: 45.588235294117647%;
		padding: 24px 4.411764705882353% 3%;
		margin-right: 0;
		margin-bottom: 45px;
		border-radius: 8px;
		box-shadow: 0 2px 0 rgba(0,0,0,0.05);
		position: relative;
		z-index: 1;
	}

	.c-flow__inner-list-sec:nth-child(6) {
		/*margin-left: 0;*/
	}

	.c-flow__inner-list-sec:nth-child(9) {
		/*margin-left: 26.911764705882353%;*/
		/*margin-right: 0;*/
	}

	.c-flow__inner-list-sec:before {
		content: "";
		display: block;
		width: 2.4vw;
		height: 4.533vw;
		background: url('/img/lp/flow_arr01.png') no-repeat center;
		background-size: contain;
		position: absolute;
		top: 50%;
		right: -5.6vw;
		z-index: 1;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}

	.c-flow__inner-list-sec:nth-child(5):before {
		display: block;
	}

	.c-flow__inner-list-sec:nth-child(2):before,
	.c-flow__inner-list-sec:nth-child(4):before,
	.c-flow__inner-list-sec:nth-child(6):before,
	.c-flow__inner-list-sec:nth-child(8):before,
	.c-flow__inner-list-sec:nth-child(10):before {
		display: none;
	}

	.c-flow__inner-list-sec .num {
		width: 28px;
		height: 28px;
		line-height: 28px;
		font-size: 12px;
		border-radius: 6px;
	}

	.c-flow__inner-list-sec .pic {
		padding: 10px 10px;
		margin-bottom: 8px;
		border-radius: 6px;
	}

	.c-flow__inner-list-sec .pic img {
		width: 30px;
	}

	.c-flow__inner-list-sec .ttl {
		line-height: 1.5;
	}

}



/**********************************

 c-faq

***********************************/
.c-faq {

}

.c-faq__inner {
	padding: 80px 0 103px;
}

@media screen and (max-width:767px){

	.c-faq__inner {
		padding: 35px 0 42px;
	}

}



/**********************************

 c-company

***********************************/
.c-company {
	background-color: #fafafa;
}

.c-company__inner {
	padding: 90px 0 100px;
}

.c-company__inner-list {
	border-top: 1px solid #dddddd;
}

.c-company__inner-list dl {
	display: flex;
	padding: 25px 0;
	border-bottom: 1px solid #dddddd;
	float: none;
}

.c-company__inner-list dl dt {
	width: 34%;
	font-weight: bold;
	text-align: right;
	float: none;
}

.c-company__inner-list dl dd {
	flex: 1;
	padding-left: 50px;
}

@media screen and (max-width:767px){

	.c-company__inner {
		padding: 35px 0 40px;
	}

	.c-company__inner-list dl {
		display: block;
		padding: 12px 0;
	}

	.c-company__inner-list dl dt {
		width: auto;
		margin-bottom: .4em;
		text-align: center;
	}

	.c-company__inner-list dl dd {
		padding-left: 0;
		text-align: center;
	}

}


/**********************************

 xxx

***********************************/

@media screen and (max-width:767px){

}





</style>